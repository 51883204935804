/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.Textarea_Button__2u221, .Textarea_ButtonRenter__3RUZE, .Textarea_ButtonOwner__2y3Vk, .Textarea_ButtonYellow__3XlH6, .Textarea_ButtonOwnerOutline__NpD3a, .Textarea_ButtonCaptain__3ztoU {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.Textarea_ButtonRenter__3RUZE {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .Textarea_ButtonRenter__3RUZE:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .Textarea_ButtonRenter__3RUZE:active {
    background: #083170;
    border: 1px solid #083170; }

.Textarea_ButtonOwner__2y3Vk {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .Textarea_ButtonOwner__2y3Vk:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .Textarea_ButtonOwner__2y3Vk:active {
    background: #154353;
    border: 1px solid #154353; }

.Textarea_ButtonYellow__3XlH6 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .Textarea_ButtonYellow__3XlH6:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .Textarea_ButtonYellow__3XlH6:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.Textarea_ButtonOwnerOutline__NpD3a {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .Textarea_ButtonOwnerOutline__NpD3a:hover {
    background: #154353;
    color: white; }

.Textarea_ButtonCaptain__3ztoU {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .Textarea_ButtonCaptain__3ztoU:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .Textarea_ButtonCaptain__3ztoU:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.Textarea_ButtonSmall__2Ua7o {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

/** @define Textarea; use strict */
.Textarea_TextareaWrapper__1ZZ__ {
  position: relative;
  width: 100%; }

.Textarea_Textarea__w6QRv {
  padding: 0.9em;
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  color: #17233c;
  resize: vertical;
  border: solid 1px #dbdfe5;
  border-radius: 6px;
  padding-top: 28px;
  padding-left: 12px;
  width: 100%; }

.Textarea_TextareaWithIcon__2nu-T {
  background-repeat: no-repeat;
  background-position: 16px 16px; }
  .Textarea_TextareaWithIcon__2nu-T:not(:-moz-placeholder-shown) {
    background-image: none !important; }
  .Textarea_TextareaWithIcon__2nu-T:not(:-ms-input-placeholder) {
    background-image: none !important; }
  .Textarea_TextareaWithIcon__2nu-T:not(:placeholder-shown) {
    background-image: none !important; }
  .Textarea_TextareaWithIcon__2nu-T::-moz-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T:-ms-input-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T::-ms-input-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T::placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T.Textarea_error__1PEsw::-moz-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T.Textarea_error__1PEsw:-ms-input-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T.Textarea_error__1PEsw::-ms-input-placeholder {
    color: #979797 !important;
    font-weight: 600; }
  .Textarea_TextareaWithIcon__2nu-T.Textarea_error__1PEsw::placeholder {
    color: #979797 !important;
    font-weight: 600; }

.Textarea_Textarea__w6QRv.Textarea_error__1PEsw {
  border-color: #bc350a; }
  .Textarea_Textarea__w6QRv.Textarea_error__1PEsw::-moz-placeholder {
    color: #bc350a; }
  .Textarea_Textarea__w6QRv.Textarea_error__1PEsw:-ms-input-placeholder {
    color: #bc350a; }
  .Textarea_Textarea__w6QRv.Textarea_error__1PEsw::-ms-input-placeholder {
    color: #bc350a; }
  .Textarea_Textarea__w6QRv.Textarea_error__1PEsw::placeholder {
    color: #bc350a; }

.Textarea_TextareaDark__HN0i7 {
  color: white;
  background: rgba(0, 0, 0, 0.16);
  border: solid 0px rgba(0, 0, 0, 0.16); }
  .Textarea_TextareaDark__HN0i7:focus {
    border: solid 0px #8e9697; }
  .Textarea_TextareaDark__HN0i7::-moz-placeholder {
    color: rgba(255, 255, 255, 0.36); }
  .Textarea_TextareaDark__HN0i7:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.36); }
  .Textarea_TextareaDark__HN0i7::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.36); }
  .Textarea_TextareaDark__HN0i7::placeholder {
    color: rgba(255, 255, 255, 0.36); }
  @media (max-width: 640px) {
    .Textarea_TextareaDark__HN0i7:-moz-placeholder-shown {
      height: 320.1px; }
    .Textarea_TextareaDark__HN0i7:-ms-input-placeholder {
      height: 320.1px; }
    .Textarea_TextareaDark__HN0i7:placeholder-shown {
      height: 320.1px; } }

textarea.Textarea_Textarea__w6QRv:-webkit-autofill,
textarea.Textarea_Textarea__w6QRv:-webkit-autofill:hover,
textarea.Textarea_Textarea__w6QRv:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

textarea.Textarea_TextareaDark__HN0i7:-webkit-autofill,
textarea.Textarea_TextareaDark__HN0i7:-webkit-autofill:hover,
textarea.Textarea_TextareaDark__HN0i7:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

.Textarea_TextareaLabel__ay8l4 {
  transform: translate(12px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #5e696a;
  transform-origin: top left;
  z-index: 1; }

.Textarea_TextareaLabelIsFocused__2Rk3r {
  transform: translate(11px, 5px) scale(0.75);
  color: #8e9697; }

.Textarea_TextareaPlaceholder__gURKJ {
  transform: translate(12px, 30px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #8e9697;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
  width: 90%;
  z-index: 1; }

.Textarea_TextareaPlaceholderShow__2gWyC {
  opacity: 0.4;
  visibility: visible;
  transition: all 200ms ease; }

.Textarea_TextareaBold__ZlVFc {
  font-weight: 600; }

.LoadingSpinner_LoadingSpinnerWrapper__1G5sM {
  position: relative; }

.LoadingSpinner_LoadingSpinnerSpacer__1f95e {
  display: inline-block;
  margin-right: 8px; }

.LoadingSpinner_LoadingSpinner__3pz-2 {
  -webkit-animation: LoadingSpinner_rotator__2WQXj 1.2s linear infinite;
          animation: LoadingSpinner_rotator__2WQXj 1.2s linear infinite;
  stroke: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -17px; }

.LoadingSpinner_LoadingSpinnerCircle__2LtzS {
  stroke-dashoffset: 0;
  transform-origin: center; }

@-webkit-keyframes LoadingSpinner_rotator__2WQXj {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@keyframes LoadingSpinner_rotator__2WQXj {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@-webkit-keyframes LoadingSpinner_dash__2DzGv {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

@keyframes LoadingSpinner_dash__2DzGv {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

.LoadingSpinner_LoadingSpinner--large__2lyH_ {
  position: relative;
  width: 60px;
  height: 60px; }
  .LoadingSpinner_LoadingSpinner--large__2lyH_.LoadingSpinner_animate__38Zv8 {
    -webkit-animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
            animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
    z-index: 2; }

.LoadingSpinner_LoadingSpinner--medium__y5mA8 {
  position: relative;
  width: 54px;
  height: 54px; }
  .LoadingSpinner_LoadingSpinner--medium__y5mA8.LoadingSpinner_animate__38Zv8 {
    -webkit-animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
            animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
    z-index: 2; }

.LoadingSpinner_LoadingSpinner--small__Jo8tu {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -17px; }
  .LoadingSpinner_LoadingSpinner--small__Jo8tu.LoadingSpinner_animate__38Zv8 {
    -webkit-animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
            animation: LoadingSpinner_rotate__vsc_Z 2s linear infinite;
    z-index: 2; }

.LoadingSpinner_LoadingSpinnerCircle__2LtzS {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  transform: translate(5px, 5px); }
  .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_large__19lEh {
    stroke-width: 6;
    stroke-dasharray: 140;
    stroke-dashoffset: 140; }
    .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_large__19lEh.LoadingSpinner_animate__38Zv8 {
      -webkit-animation: LoadingSpinner_animateLarge__37Z-l 1.8s linear infinite;
              animation: LoadingSpinner_animateLarge__37Z-l 1.8s linear infinite; }
  .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_medium__1xf77 {
    stroke-width: 6;
    stroke-dasharray: 125;
    stroke-dashoffset: 125; }
    .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_medium__1xf77.LoadingSpinner_animate__38Zv8 {
      -webkit-animation: LoadingSpinner_animateMedium__33qHb 1.8s linear infinite;
              animation: LoadingSpinner_animateMedium__33qHb 1.8s linear infinite; }
  .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_small__ytyFG {
    stroke-width: 4;
    stroke-dasharray: 80;
    stroke-dashoffset: 80; }
    .LoadingSpinner_LoadingSpinnerCircle__2LtzS.LoadingSpinner_small__ytyFG.LoadingSpinner_animate__38Zv8 {
      -webkit-animation: LoadingSpinner_animateSmall__1V6Lg 1.2s linear infinite;
              animation: LoadingSpinner_animateSmall__1V6Lg 1.2s linear infinite; }

.LoadingSpinner_LoadingSpinnerBg__plCBF {
  stroke: #f4f5f6 !important;
  stroke-dasharray: 0 !important;
  stroke-dashoffset: 0 !important; }

.LoadingSpinner_LoadingSpinnerBgWrapper__1LEfe {
  position: absolute !important;
  left: 0; }

@-webkit-keyframes LoadingSpinner_animateLarge__37Z-l {
  0%,
  100% {
    stroke-dashoffset: 140; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 280; } }

@keyframes LoadingSpinner_animateLarge__37Z-l {
  0%,
  100% {
    stroke-dashoffset: 140; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 280; } }

@-webkit-keyframes LoadingSpinner_animateMedium__33qHb {
  0%,
  100% {
    stroke-dashoffset: 125; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 250; } }

@keyframes LoadingSpinner_animateMedium__33qHb {
  0%,
  100% {
    stroke-dashoffset: 125; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 250; } }

@-webkit-keyframes LoadingSpinner_animateSmall__1V6Lg {
  0%,
  100% {
    stroke-dashoffset: 80; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 160; } }

@keyframes LoadingSpinner_animateSmall__1V6Lg {
  0%,
  100% {
    stroke-dashoffset: 80; }
  50% {
    stroke-dashoffset: 0; }
  50.1% {
    stroke-dashoffset: 160; } }

@-webkit-keyframes LoadingSpinner_rotate__vsc_Z {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes LoadingSpinner_rotate__vsc_Z {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.Button_Button__2pL_i, .Button_ButtonRenter__2VbMu, .Button_ButtonOwner__3wYkw, .Button_ButtonYellow__384zX, .Button_ButtonOwnerOutline__g0LjD, .Button_ButtonCaptain__3hxrt {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.Button_ButtonRenter__2VbMu {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .Button_ButtonRenter__2VbMu:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .Button_ButtonRenter__2VbMu:active {
    background: #083170;
    border: 1px solid #083170; }

.Button_ButtonOwner__3wYkw {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .Button_ButtonOwner__3wYkw:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .Button_ButtonOwner__3wYkw:active {
    background: #154353;
    border: 1px solid #154353; }

.Button_ButtonYellow__384zX {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .Button_ButtonYellow__384zX:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .Button_ButtonYellow__384zX:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.Button_ButtonOwnerOutline__g0LjD {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .Button_ButtonOwnerOutline__g0LjD:hover {
    background: #154353;
    color: white; }

.Button_ButtonCaptain__3hxrt {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .Button_ButtonCaptain__3hxrt:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .Button_ButtonCaptain__3hxrt:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.Button_ButtonSmall__VYYzP {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.Button_button__3_Ozh {
  border-radius: 6px;
  background-color: #0751c2;
  padding: 0px 10px;
  display: inline-block;
  text-decoration: none;
  white-space: normal;
  text-align: center;
  height: 55px;
  cursor: pointer;
  font-family: "AvenirNext";
  font-size: 14px;
  font-weight: bold;
  text-transform: unset;
  margin: 0; }
  .Button_button__3_Ozh.Button_uppercase__3nlmK {
    text-transform: uppercase !important; }
  .Button_button__3_Ozh.Button_small__nAPfO {
    padding: 10px 22px; }
  .Button_button__3_Ozh.Button_raised__2yQrI {
    border: solid 1px #dbdfe5;
    background-color: #ffffff;
    padding: 17px; }

.Button_buttonText__2Bn6K {
  text-transform: uppercase;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: bold;
  line-height: 0.79;
  text-transform: inherit; }

.Button_button__3_Ozh.Button_small__nAPfO .Button_buttonText__2Bn6K {
  font-size: 10px;
  font-weight: bold; }

.Button_renterBg__1gxIO {
  background: #0751c2;
  color: white; }
  .Button_renterBg__1gxIO:hover {
    background: #0a4195; }
  .Button_renterBg__1gxIO:active {
    background: #083170; }
  .Button_renterBg__1gxIO.Button_disabled__3O5v9 {
    background: #dbdfe5;
    border: 1px solid #dbdfe5;
    color: white; }
  .Button_renterBg__1gxIO.Button_outline__1_EsF {
    border: 1px solid #0751c2;
    background: white;
    color: #dbdfe5; }
    .Button_renterBg__1gxIO.Button_outline__1_EsF .Button_buttonText__2Bn6K {
      color: #0751c2; }
    .Button_renterBg__1gxIO.Button_outline__1_EsF:hover {
      background: #0a4195; }
      .Button_renterBg__1gxIO.Button_outline__1_EsF:hover .Button_buttonText__2Bn6K {
        color: white; }
    .Button_renterBg__1gxIO.Button_outline__1_EsF:active {
      border: 1px solid #083170;
      color: #083170; }
    .Button_renterBg__1gxIO.Button_outline__1_EsF.Button_disabled__3O5v9 {
      background: white;
      border: 1px solid #dbdfe5; }
      .Button_renterBg__1gxIO.Button_outline__1_EsF.Button_disabled__3O5v9 .Button_buttonText__2Bn6K {
        color: #dbdfe5; }

.Button_ownerBg__2VfPI {
  background: #0e7873;
  color: white; }
  .Button_ownerBg__2VfPI:hover {
    background: #0c605c; }
  .Button_ownerBg__2VfPI:active {
    background: #154353; }
  .Button_ownerBg__2VfPI.Button_disabled__3O5v9 {
    background: #dbdfe5;
    border: 1px solid #dbdfe5;
    color: white; }
  .Button_ownerBg__2VfPI.Button_outline__1_EsF {
    border: 1px solid #0e7873;
    background: white;
    color: #dbdfe5; }
    .Button_ownerBg__2VfPI.Button_outline__1_EsF .Button_buttonText__2Bn6K {
      color: #0e7873; }
    .Button_ownerBg__2VfPI.Button_outline__1_EsF:hover {
      background: #0c605c; }
      .Button_ownerBg__2VfPI.Button_outline__1_EsF:hover .Button_buttonText__2Bn6K {
        color: white; }
    .Button_ownerBg__2VfPI.Button_outline__1_EsF:active {
      border: 1px solid #154353;
      color: #154353; }
    .Button_ownerBg__2VfPI.Button_outline__1_EsF.Button_disabled__3O5v9 {
      background: white;
      border: 1px solid #dbdfe5; }
      .Button_ownerBg__2VfPI.Button_outline__1_EsF.Button_disabled__3O5v9 .Button_buttonText__2Bn6K {
        color: #dbdfe5; }

.Button_captainBg__3brgf {
  background: #00acb7;
  color: white; }
  .Button_captainBg__3brgf:hover {
    background: #02949d; }
  .Button_captainBg__3brgf:active {
    background: #0e7873; }
  .Button_captainBg__3brgf.Button_disabled__3O5v9 {
    background: #dbdfe5;
    border: 1px solid #dbdfe5;
    color: white; }
  .Button_captainBg__3brgf.Button_outline__1_EsF {
    border: 1px solid #00acb7;
    background: white;
    color: #dbdfe5; }
    .Button_captainBg__3brgf.Button_outline__1_EsF .Button_buttonText__2Bn6K {
      color: #00acb7; }
    .Button_captainBg__3brgf.Button_outline__1_EsF:hover {
      background: #02949d; }
      .Button_captainBg__3brgf.Button_outline__1_EsF:hover .Button_buttonText__2Bn6K {
        color: white; }
    .Button_captainBg__3brgf.Button_outline__1_EsF:active {
      border: 1px solid #0e7873;
      color: #0e7873; }
    .Button_captainBg__3brgf.Button_outline__1_EsF.Button_disabled__3O5v9 {
      background: white;
      border: 1px solid #dbdfe5; }
      .Button_captainBg__3brgf.Button_outline__1_EsF.Button_disabled__3O5v9 .Button_buttonText__2Bn6K {
        color: #dbdfe5; }

.Button_yellowBg__1MBMW {
  background: #f4c064;
  color: #17233c; }
  .Button_yellowBg__1MBMW:hover {
    background: #e7a433; }
  .Button_yellowBg__1MBMW:active {
    background: #d28d19; }
  .Button_yellowBg__1MBMW.Button_disabled__3O5v9 {
    background: #dbdfe5;
    border: 1px solid #dbdfe5;
    color: white; }
  .Button_yellowBg__1MBMW.Button_outline__1_EsF {
    border: 1px solid #f4c064;
    background: white;
    color: #dbdfe5; }
    .Button_yellowBg__1MBMW.Button_outline__1_EsF .Button_buttonText__2Bn6K {
      color: #f4c064; }
    .Button_yellowBg__1MBMW.Button_outline__1_EsF:hover {
      background: #e7a433; }
      .Button_yellowBg__1MBMW.Button_outline__1_EsF:hover .Button_buttonText__2Bn6K {
        color: #17233c; }
    .Button_yellowBg__1MBMW.Button_outline__1_EsF:active {
      border: 1px solid #d28d19;
      color: #d28d19; }
    .Button_yellowBg__1MBMW.Button_outline__1_EsF.Button_disabled__3O5v9 {
      background: white;
      border: 1px solid #dbdfe5; }
      .Button_yellowBg__1MBMW.Button_outline__1_EsF.Button_disabled__3O5v9 .Button_buttonText__2Bn6K {
        color: #dbdfe5; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.ToggleSwitch_Button__2YZch, .ToggleSwitch_ButtonRenter__e95lW, .ToggleSwitch_ButtonOwner__IwLlD, .ToggleSwitch_ButtonYellow__2D_78, .ToggleSwitch_ButtonOwnerOutline__y727C, .ToggleSwitch_ButtonCaptain__7uDhy {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.ToggleSwitch_ButtonRenter__e95lW {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .ToggleSwitch_ButtonRenter__e95lW:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .ToggleSwitch_ButtonRenter__e95lW:active {
    background: #083170;
    border: 1px solid #083170; }

.ToggleSwitch_ButtonOwner__IwLlD {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .ToggleSwitch_ButtonOwner__IwLlD:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .ToggleSwitch_ButtonOwner__IwLlD:active {
    background: #154353;
    border: 1px solid #154353; }

.ToggleSwitch_ButtonYellow__2D_78 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .ToggleSwitch_ButtonYellow__2D_78:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .ToggleSwitch_ButtonYellow__2D_78:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.ToggleSwitch_ButtonOwnerOutline__y727C {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .ToggleSwitch_ButtonOwnerOutline__y727C:hover {
    background: #154353;
    color: white; }

.ToggleSwitch_ButtonCaptain__7uDhy {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .ToggleSwitch_ButtonCaptain__7uDhy:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .ToggleSwitch_ButtonCaptain__7uDhy:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.ToggleSwitch_ButtonSmall__32XD1 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.ToggleSwitch_toggleSwitchWrapper__1tpqU {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }
  .ToggleSwitch_toggleSwitchWrapper__1tpqU:focus {
    outline: none; }

.ToggleSwitch_toggleSwitch__2DAIr {
  width: 59px;
  height: 36px;
  padding: 5px;
  padding-right: 6px;
  padding-left: 6px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #0751c2;
  border-radius: 5px;
  transition: color 280ms ease-in-out;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px; }
  .ToggleSwitch_toggleSwitch__2DAIr.ToggleSwitch_disabled__2TH8p {
    border: 1px solid #c0c0c0; }

.ToggleSwitch_toggleSwitchBox__23mVZ {
  margin-left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #0751c2;
  transition: all 280ms ease-in-out; }
  .ToggleSwitch_toggleSwitchBox__23mVZ.ToggleSwitch_disabled__2TH8p {
    margin-left: 0;
    background: #c0c0c0; }

.ToggleSwitch_toggleLabel__1LpkY {
  margin-right: 1em;
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.Checkbox_Button__2Ivnh, .Checkbox_ButtonRenter__2KIVM, .Checkbox_ButtonOwner__X9XtS, .Checkbox_ButtonYellow__14oRK, .Checkbox_ButtonOwnerOutline__3fj3s, .Checkbox_ButtonCaptain__3pe-3 {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.Checkbox_ButtonRenter__2KIVM {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .Checkbox_ButtonRenter__2KIVM:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .Checkbox_ButtonRenter__2KIVM:active {
    background: #083170;
    border: 1px solid #083170; }

.Checkbox_ButtonOwner__X9XtS {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .Checkbox_ButtonOwner__X9XtS:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .Checkbox_ButtonOwner__X9XtS:active {
    background: #154353;
    border: 1px solid #154353; }

.Checkbox_ButtonYellow__14oRK {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .Checkbox_ButtonYellow__14oRK:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .Checkbox_ButtonYellow__14oRK:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.Checkbox_ButtonOwnerOutline__3fj3s {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .Checkbox_ButtonOwnerOutline__3fj3s:hover {
    background: #154353;
    color: white; }

.Checkbox_ButtonCaptain__3pe-3 {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .Checkbox_ButtonCaptain__3pe-3:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .Checkbox_ButtonCaptain__3pe-3:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.Checkbox_ButtonSmall__1cjdh {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.Checkbox_CheckboxWrapper__326iI {
  align-items: flex-start;
  display: flex; }

.Checkbox_Checkbox__U8maG {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #8e9697;
  border-radius: 3px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: white;
  position: relative;
  outline: none;
  margin-right: 15px;
  transition: all ease-in-out 0.2s; }
  @media only screen and (max-width: 370px) {
    .Checkbox_Checkbox__U8maG {
      margin-right: 8px; } }
  .Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwner__1LD6E {
    border: 1px solid #8e9697; }
  .Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwnerSecondary__2qomx {
    border: 1px solid #8e9697; }
  .Checkbox_Checkbox__U8maG.Checkbox_CheckboxRegular__3j-Gx {
    background-color: #dbdfe5; }
  .Checkbox_Checkbox__U8maG.Checkbox_Checkbox--small__7gHna {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border: 1px solid #8e9697; }
  .Checkbox_Checkbox__U8maG.Checkbox_big__1wfOU {
    margin-right: 32px; }

.Checkbox_Checkbox__U8maG:checked {
  border: 1px solid #0751c2;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: all ease-in-out 0.2s; }
  .Checkbox_Checkbox__U8maG:checked.Checkbox_CheckboxOwner__1LD6E {
    background-color: #0a5551;
    border: 1px solid #f4c064; }
  .Checkbox_Checkbox__U8maG:checked.Checkbox_CheckboxOwnerSecondary__2qomx {
    background-color: white;
    border: 1px solid #0e7873; }

.Checkbox_Checkbox__U8maG::before,
.Checkbox_Checkbox__U8maG::after {
  margin: auto;
  content: "";
  display: block;
  position: absolute; }

.Checkbox_Checkbox__U8maG::after {
  opacity: 0;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 2px;
  transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  border-style: solid;
  border-color: #0751c2;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  transform: scale(0.5) rotate(45deg); }
  .Checkbox_Checkbox__U8maG::after.Checkbox_CheckboxOwner__1LD6E {
    border-color: #f4c064; }
  .Checkbox_Checkbox__U8maG::after.Checkbox_CheckboxOwnerSecondary__2qomx {
    border-color: #0e7873; }

.Checkbox_Checkbox__U8maG:checked::after {
  opacity: 0;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 2px;
  transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  border-style: solid;
  border-color: #0751c2;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  transform: scale(0.5) rotate(45deg);
  opacity: 1;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  transform: scale(1) rotate(45deg); }

.Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwner__1LD6E:checked::after {
  border-color: #f4c064; }

.Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwnerSecondary__2qomx:checked::after {
  border-color: #0e7873; }

.Checkbox_Checkbox__U8maG:checked + label {
  color: #0751c2; }

.Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwner__1LD6E:checked + label {
  color: #0e7873; }

.Checkbox_Checkbox__U8maG.Checkbox_CheckboxOwnerSecondary__2qomx:checked + label {
  color: #0e7873; }

.Checkbox_LabelOwners__1tF88 {
  font-size: 16px;
  font-weight: 600; }

.Checkbox_LabelWrapper__38-wq.Checkbox_big__1wfOU {
  flex: 1 1; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.InputText_Button__1-lFQ, .InputText_ButtonRenter__25KhY, .InputText_ButtonOwner__2m5ae, .InputText_ButtonYellow__21t_z, .InputText_ButtonOwnerOutline__IZtyY, .InputText_ButtonCaptain__2EYAh {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.InputText_ButtonRenter__25KhY {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .InputText_ButtonRenter__25KhY:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .InputText_ButtonRenter__25KhY:active {
    background: #083170;
    border: 1px solid #083170; }

.InputText_ButtonOwner__2m5ae {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .InputText_ButtonOwner__2m5ae:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .InputText_ButtonOwner__2m5ae:active {
    background: #154353;
    border: 1px solid #154353; }

.InputText_ButtonYellow__21t_z {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .InputText_ButtonYellow__21t_z:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .InputText_ButtonYellow__21t_z:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.InputText_ButtonOwnerOutline__IZtyY {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .InputText_ButtonOwnerOutline__IZtyY:hover {
    background: #154353;
    color: white; }

.InputText_ButtonCaptain__2EYAh {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .InputText_ButtonCaptain__2EYAh:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .InputText_ButtonCaptain__2EYAh:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.InputText_ButtonSmall__1o7Zy {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.InputText_InputTextFull__Cyqec {
  width: 100%; }

.InputText_InputTextWrapper__1mK8Y {
  position: relative; }

.InputText_InputTextContent__92ysS {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0; }

.InputText_TextInputCell__3Dvwy {
  box-sizing: inherit;
  flex: 0 0 100%;
  position: relative; }

.InputText_InputTextError__zIg6i {
  position: absolute; }

.InputText_InputText__1SJ7B:-webkit-autofill::first-line {
  font-size: 16px;
  font-weight: 400;
  font-family: "AvenirNext"; }

.InputText_InputTextBottomSpacing__3xW3h {
  margin-bottom: 32px; }

.InputText_InputText__1SJ7B {
  padding: 0.9em;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  color: #17233c;
  background-color: #fff;
  border: solid 1px #dbdfe5;
  border-radius: 6px;
  height: 50px;
  transition: all 200ms; }
  .InputText_InputText__1SJ7B::-moz-placeholder {
    color: #b4b4b4; }
  .InputText_InputText__1SJ7B:-ms-input-placeholder {
    color: #b4b4b4; }
  .InputText_InputText__1SJ7B::-ms-input-placeholder {
    color: #b4b4b4; }
  .InputText_InputText__1SJ7B::placeholder {
    color: #b4b4b4; }

.InputText_InputTextWithAnimatedLabel__2RVRL {
  font-size: 16px;
  padding-top: 32px;
  padding-left: 10px; }

.InputText_InputTextBold__3koby {
  font-weight: 600; }

.InputText_InputTextWithIconBig__3ivOm {
  padding-left: 3em; }

.InputText_InputText__1SJ7B:disabled {
  cursor: not-allowed; }

.InputText_InputText__1SJ7B:focus {
  border: solid 1px #8e9697; }
  .InputText_InputText__1SJ7B:focus.InputText_InputTextInvalid__3fDc2 {
    border: solid 1px #bc350a; }

/* sizing */
.InputText_InputTextShort__3Jcni {
  padding-top: 8px;
  padding-bottom: 8px; }

.InputText_InputText__1SJ7B:disabled {
  opacity: 0.5;
  background-color: #f2f2f2;
  border-color: #e6e6e6; }

.InputText_InputTextLabel__JasAU {
  transform: translate(12px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #5e696a;
  transform-origin: top left; }

.InputText_InputTextLabelIsFocused__1YQbl {
  transform: translate(11px, 5px) scale(0.75);
  color: #8e9697; }

.InputText_InputTextLabelWithIcon__2GoTU {
  transform: translate(50px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #5e696a;
  transform-origin: top left; }

.InputText_InputTextLabelWithIcon__2GoTU.InputText_InputTextLabelIsFocused__1YQbl {
  transform: translate(50px, 5px) scale(0.75);
  color: #8e9697; }

.InputText_InputTextPrefix__3u0U0 {
  transform: translate(12px, 23px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
  font-weight: 600; }

.InputText_InputTextPrefixShow__dW0DF {
  opacity: 1;
  visibility: visible;
  transition: all 200ms ease; }

.InputText_InputTextHasPrefix__2UdtJ {
  padding-left: 22px; }

/* Chrome, Safari, Edge, Opera */
.InputText_InputText__1SJ7B::-webkit-outer-spin-button,
.InputText_InputText__1SJ7B::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
.InputText_InputText__1SJ7B[type="number"] {
  -moz-appearance: textfield; }

.InputText_InputText__1SJ7B::-webkit-validation-bubble-message {
  display: none !important; }

.InputText_InputTextInvalid__3fDc2 {
  border-color: #bc350a;
  color: #bc350a; }

.InputText_InputTextInvalid__3fDc2 ~ i:before {
  color: #bc350a; }

.InputText_TooltipWrapper__16O78 {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 15px; }

.InputText_InputTextToolTipWrapper__3HijR {
  color: #5e696a;
  text-align: left;
  font-size: 14px; }
  @media (max-width: 640px) {
    .InputText_InputTextToolTipWrapper__3HijR {
      font-size: 12px; } }

input.InputText_InputText__1SJ7B:-webkit-autofill,
input.InputText_InputText__1SJ7B:-webkit-autofill:hover,
input.InputText_InputText__1SJ7B:-webkit-autofill:active,
input.InputText_InputText__1SJ7B:-webkit-autofill:focus,
input.InputText_InputText__1SJ7B:-webkit-autofill::first-line {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

input.InputText_InputTextWithAnimatedLabel__2RVRL:-webkit-autofill,
input.InputText_InputTextWithAnimatedLabel__2RVRL:-webkit-autofill:hover,
input.InputText_InputTextWithAnimatedLabel__2RVRL:-webkit-autofill:active,
input.InputText_InputTextWithAnimatedLabel__2RVRL:-webkit-autofill:focus,
input.InputText_InputTextWithAnimatedLabel__2RVRL:-webkit-autofill::first-line {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  font-weight: 600; }

.InputText_InputTextPlaceholder__2HxBM {
  transform: translate(11px, 22px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #8e9697;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease; }

.InputText_InputTextPlaceholderShow__3vNmz {
  opacity: 0.4;
  visibility: visible;
  transition: all 200ms ease; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SelectInput_Button__NqKoX, .SelectInput_ButtonRenter__35tLz, .SelectInput_ButtonOwner__3w2P8, .SelectInput_ButtonYellow__19E_-, .SelectInput_ButtonOwnerOutline__2FeUj, .SelectInput_ButtonCaptain__2v4u4 {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SelectInput_ButtonRenter__35tLz {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SelectInput_ButtonRenter__35tLz:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SelectInput_ButtonRenter__35tLz:active {
    background: #083170;
    border: 1px solid #083170; }

.SelectInput_ButtonOwner__3w2P8 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SelectInput_ButtonOwner__3w2P8:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SelectInput_ButtonOwner__3w2P8:active {
    background: #154353;
    border: 1px solid #154353; }

.SelectInput_ButtonYellow__19E_- {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SelectInput_ButtonYellow__19E_-:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SelectInput_ButtonYellow__19E_-:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SelectInput_ButtonOwnerOutline__2FeUj {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SelectInput_ButtonOwnerOutline__2FeUj:hover {
    background: #154353;
    color: white; }

.SelectInput_ButtonCaptain__2v4u4 {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SelectInput_ButtonCaptain__2v4u4:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SelectInput_ButtonCaptain__2v4u4:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SelectInput_ButtonSmall__1Ksy7 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.PhotoCarousel_Button__3wPKK, .PhotoCarousel_ButtonRenter__BAI5a, .PhotoCarousel_ButtonOwner__3u10b, .PhotoCarousel_ButtonYellow__2lHVc, .PhotoCarousel_ButtonOwnerOutline__23tjy, .PhotoCarousel_ButtonCaptain__33Bs9 {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.PhotoCarousel_ButtonRenter__BAI5a {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .PhotoCarousel_ButtonRenter__BAI5a:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .PhotoCarousel_ButtonRenter__BAI5a:active {
    background: #083170;
    border: 1px solid #083170; }

.PhotoCarousel_ButtonOwner__3u10b {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .PhotoCarousel_ButtonOwner__3u10b:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .PhotoCarousel_ButtonOwner__3u10b:active {
    background: #154353;
    border: 1px solid #154353; }

.PhotoCarousel_ButtonYellow__2lHVc {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .PhotoCarousel_ButtonYellow__2lHVc:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .PhotoCarousel_ButtonYellow__2lHVc:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.PhotoCarousel_ButtonOwnerOutline__23tjy {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .PhotoCarousel_ButtonOwnerOutline__23tjy:hover {
    background: #154353;
    color: white; }

.PhotoCarousel_ButtonCaptain__33Bs9 {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .PhotoCarousel_ButtonCaptain__33Bs9:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .PhotoCarousel_ButtonCaptain__33Bs9:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.PhotoCarousel_ButtonSmall__PoZXk {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.PhotoCarousel_carouselSpan__E7dz1 {
  padding-left: 10px;
  line-height: 30px;
  color: #ffffff; }

.PhotoCarousel_carouselIcon__3c0XZ {
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 1;
  width: 100%; }

.PhotoCarousel_dot__S3twt {
  transition: opacity 0.25s ease-in;
  border-bottom-left-radius: 50% !important;
  opacity: 0.7;
  filter: alpha(opacity=30);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 4.5px;
  background: #808080; }
  .PhotoCarousel_dot__S3twt.PhotoCarousel_selected__1ST_G {
    opacity: 1;
    filter: alpha(opacity=100);
    background: #fff; }
  .PhotoCarousel_dot__S3twt:hover {
    opacity: 1;
    filter: alpha(opacity=100); }

.PhotoCarousel_CardIsLoading__r6fQK::after {
  background-image: inherit; }

.PhotoCarousel_CardIsLoading__r6fQK {
  color: transparent !important;
  background-color: #dbdfe5 !important;
  background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, 0.3) 50%, transparent 66%) !important;
  background-size: 300% 100% !important;
  -webkit-animation: PhotoCarousel_shine__1laZk 2s infinite;
          animation: PhotoCarousel_shine__1laZk 2s infinite; }

@-webkit-keyframes PhotoCarousel_shine__1laZk {
  0% {
    background-position: right; } }

@keyframes PhotoCarousel_shine__1laZk {
  0% {
    background-position: right; } }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.StarRating_Button__3fkmD, .StarRating_ButtonRenter__yd_1_, .StarRating_ButtonOwner__1Gak7, .StarRating_ButtonYellow__3Q9Dv, .StarRating_ButtonOwnerOutline__3-tqH, .StarRating_ButtonCaptain__15nv3 {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.StarRating_ButtonRenter__yd_1_ {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .StarRating_ButtonRenter__yd_1_:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .StarRating_ButtonRenter__yd_1_:active {
    background: #083170;
    border: 1px solid #083170; }

.StarRating_ButtonOwner__1Gak7 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .StarRating_ButtonOwner__1Gak7:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .StarRating_ButtonOwner__1Gak7:active {
    background: #154353;
    border: 1px solid #154353; }

.StarRating_ButtonYellow__3Q9Dv {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .StarRating_ButtonYellow__3Q9Dv:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .StarRating_ButtonYellow__3Q9Dv:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.StarRating_ButtonOwnerOutline__3-tqH {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .StarRating_ButtonOwnerOutline__3-tqH:hover {
    background: #154353;
    color: white; }

.StarRating_ButtonCaptain__15nv3 {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .StarRating_ButtonCaptain__15nv3:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .StarRating_ButtonCaptain__15nv3:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.StarRating_ButtonSmall__3Ok3T {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.StarRating_starRating__2CGTJ {
  display: flex;
  align-items: center; }

.StarRating_starRatingIcon__1Nmwz {
  margin: 0 7px 0 0;
  display: flex;
  align-items: center; }
  .StarRating_starRatingIcon__1Nmwz svg {
    width: 14px;
    height: 14px; }

.StarRating_starRatingText__1t1PH {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #17233c;
  font-weight: 600; }

.StarRating_starNoRatingText__2wfAc {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #aaaaaa;
  font-weight: 600; }

.StarRating_starRatingTextRight__2PpZP {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #91999a;
  margin-left: 5px;
  font-weight: 600; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.ErrorMessage_Button__Jz1Wr, .ErrorMessage_ButtonRenter__2vXJ9, .ErrorMessage_ButtonOwner__I02nd, .ErrorMessage_ButtonYellow__2kVuo, .ErrorMessage_ButtonOwnerOutline__1iXCd, .ErrorMessage_ButtonCaptain__137sV {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.ErrorMessage_ButtonRenter__2vXJ9 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .ErrorMessage_ButtonRenter__2vXJ9:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .ErrorMessage_ButtonRenter__2vXJ9:active {
    background: #083170;
    border: 1px solid #083170; }

.ErrorMessage_ButtonOwner__I02nd {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .ErrorMessage_ButtonOwner__I02nd:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .ErrorMessage_ButtonOwner__I02nd:active {
    background: #154353;
    border: 1px solid #154353; }

.ErrorMessage_ButtonYellow__2kVuo {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .ErrorMessage_ButtonYellow__2kVuo:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .ErrorMessage_ButtonYellow__2kVuo:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.ErrorMessage_ButtonOwnerOutline__1iXCd {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .ErrorMessage_ButtonOwnerOutline__1iXCd:hover {
    background: #154353;
    color: white; }

.ErrorMessage_ButtonCaptain__137sV {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .ErrorMessage_ButtonCaptain__137sV:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .ErrorMessage_ButtonCaptain__137sV:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.ErrorMessage_ButtonSmall__3FHJ_ {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.ErrorMessage_ErrorMessage__3itUV {
  background-color: transparent;
  color: #bc350a; }

.ErrorMessage_ErrorMessageItem__3WdPF {
  font-size: 12px;
  padding: 0;
  line-height: 18px;
  margin-bottom: 12px;
  margin-top: 4px; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.Counter_Button__A5sAc, .Counter_ButtonRenter__-mzxr, .Counter_ButtonOwner__3Ghj6, .Counter_ButtonYellow__3gwh1, .Counter_ButtonOwnerOutline__1wCMz, .Counter_ButtonCaptain__xMsXn {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.Counter_ButtonRenter__-mzxr {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .Counter_ButtonRenter__-mzxr:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .Counter_ButtonRenter__-mzxr:active {
    background: #083170;
    border: 1px solid #083170; }

.Counter_ButtonOwner__3Ghj6 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .Counter_ButtonOwner__3Ghj6:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .Counter_ButtonOwner__3Ghj6:active {
    background: #154353;
    border: 1px solid #154353; }

.Counter_ButtonYellow__3gwh1 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .Counter_ButtonYellow__3gwh1:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .Counter_ButtonYellow__3gwh1:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.Counter_ButtonOwnerOutline__1wCMz {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .Counter_ButtonOwnerOutline__1wCMz:hover {
    background: #154353;
    color: white; }

.Counter_ButtonCaptain__xMsXn {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .Counter_ButtonCaptain__xMsXn:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .Counter_ButtonCaptain__xMsXn:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.Counter_ButtonSmall__2QZr5 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.Counter_counterWrapper__jLfI5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.Counter_counterButton__mDgau:focus {
  outline: 0; }

.Counter_counterLabel__Y5ZJP {
  color: #17233c;
  text-align: center;
  border: none; }

.Counter_counterLabelSpan__Kci02 {
  font-size: 18px;
  font-weight: 500; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.CompoundSlider_Button__3cTKn, .CompoundSlider_ButtonRenter__1s9xN, .CompoundSlider_ButtonOwner__2MoSa, .CompoundSlider_ButtonYellow__2AQoj, .CompoundSlider_ButtonOwnerOutline__1-g2g, .CompoundSlider_ButtonCaptain__10_eU {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.CompoundSlider_ButtonRenter__1s9xN {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .CompoundSlider_ButtonRenter__1s9xN:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .CompoundSlider_ButtonRenter__1s9xN:active {
    background: #083170;
    border: 1px solid #083170; }

.CompoundSlider_ButtonOwner__2MoSa {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .CompoundSlider_ButtonOwner__2MoSa:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .CompoundSlider_ButtonOwner__2MoSa:active {
    background: #154353;
    border: 1px solid #154353; }

.CompoundSlider_ButtonYellow__2AQoj {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .CompoundSlider_ButtonYellow__2AQoj:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .CompoundSlider_ButtonYellow__2AQoj:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.CompoundSlider_ButtonOwnerOutline__1-g2g {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .CompoundSlider_ButtonOwnerOutline__1-g2g:hover {
    background: #154353;
    color: white; }

.CompoundSlider_ButtonCaptain__10_eU {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .CompoundSlider_ButtonCaptain__10_eU:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .CompoundSlider_ButtonCaptain__10_eU:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.CompoundSlider_ButtonSmall__w1MT9 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.CompoundSlider_compoundSliderLabel__26RDN {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px; }

.CompoundSlider_compoundSliderValues__3hzDl {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px; }

.CompoundSlider_compoundSliderWrapper__2mTt8 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em; }

.rc-slider {
  position: relative;
  height: 25px;
  padding: 5px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 10px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #dbdfe5;
  height: 3px;
  border-radius: 10px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 3px;
  border-radius: 10px;
  background-color: #72d4ba; }

.rc-slider-handle {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -12.5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #0751c2;
  background-color: #0751c2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  touch-action: pan-x; }

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa; }

.rc-slider-handle:focus {
  outline: none; }

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-dot-reverse {
  margin-right: -4px; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

@-webkit-keyframes CompoundSlider_rcSliderTooltipZoomDownIn__1F-RG {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }

@keyframes CompoundSlider_rcSliderTooltipZoomDownIn__1F-RG {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }

@-webkit-keyframes CompoundSlider_rcSliderTooltipZoomDownOut__25gTW {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }

@keyframes CompoundSlider_rcSliderTooltipZoomDownOut__25gTW {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatKeyInformation_Button__1j_qL, .BoatKeyInformation_ButtonRenter__21vAa, .BoatKeyInformation_ButtonOwner__2bbEs, .BoatKeyInformation_ButtonYellow__1XErX, .BoatKeyInformation_ButtonOwnerOutline__3b__o, .BoatKeyInformation_ButtonCaptain__2-UUh {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatKeyInformation_ButtonRenter__21vAa {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatKeyInformation_ButtonRenter__21vAa:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatKeyInformation_ButtonRenter__21vAa:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatKeyInformation_ButtonOwner__2bbEs {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatKeyInformation_ButtonOwner__2bbEs:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatKeyInformation_ButtonOwner__2bbEs:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatKeyInformation_ButtonYellow__1XErX {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatKeyInformation_ButtonYellow__1XErX:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatKeyInformation_ButtonYellow__1XErX:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatKeyInformation_ButtonOwnerOutline__3b__o {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatKeyInformation_ButtonOwnerOutline__3b__o:hover {
    background: #154353;
    color: white; }

.BoatKeyInformation_ButtonCaptain__2-UUh {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatKeyInformation_ButtonCaptain__2-UUh:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatKeyInformation_ButtonCaptain__2-UUh:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatKeyInformation_ButtonSmall__343Ub {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatKeyInformation_BoatKeyInformation__Yur55 {
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 28px 30px 24px 24px;
  width: 100%; }
  .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ {
    border-bottom: 1px solid #dbdfe5;
    padding-top: 23px;
    padding-bottom: 21px;
    display: flex;
    align-items: flex-start; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ.BoatKeyInformation_BoatKeyInformationNoBorder__I2tia {
      border-bottom: none; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ .BoatKeyInformation_BoatKeyInformationIcon__2ilRU {
      min-width: 1.5em;
      font: normal normal normal 20px/1 BoatsetterV4; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ .BoatKeyInformation_BoatKeyInformationIconBig__ilQXB {
      font: normal normal normal 26px/1 BoatsetterV4; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ .BoatKeyInformation_BoatKeyInformationText__vKi2X {
      flex-grow: 1; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ:first-child {
      padding-top: 0px; }
    .BoatKeyInformation_BoatKeyInformation__Yur55 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ:last-child {
      padding-bottom: 0px; }

.BoatKeyInformation_BoatKeyInformationBlue__1Sgq3 {
  border: 1px solid #bde3fd;
  background: #fbfdff; }
  .BoatKeyInformation_BoatKeyInformationBlue__1Sgq3 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ {
    border-bottom: 1px solid #bde3fd; }
    .BoatKeyInformation_BoatKeyInformationBlue__1Sgq3 .BoatKeyInformation_BoatKeyInformationItem__2PZtQ:last-child {
      border-bottom: none; }
  .BoatKeyInformation_BoatKeyInformationBlue__1Sgq3 .BoatKeyInformation_BoatKeyInformationItemNoBorder__31exR {
    border-bottom: none; }

.BoatKeyInformation_BoatKeyInformationTextWrapper__3w9eZ {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.6; }

@media only screen and (max-width: 960px) {
  .BoatKeyInformation_BoatKeyInformation-item__1A4Ho {
    padding: 1em 0; } }

.BoatKeyInformation_LabelMute__ZZma_ {
  background: #d5dae1;
  color: #17233c !important; }

.BoatKeyInformation_LabelWarning__3J23i {
  background: #f4c064;
  color: #17233c !important; }

.BoatKeyInformation_LabelGreenLight__2Ms2D {
  background-color: #2a8401;
  color: #ffffff !important; }

.BoatKeyInformation_LabelDanger__3UNoD {
  background-color: #bc350a;
  color: #ffffff !important; }

.BoatKeyInformation_BoatKeyInformationLabel__26fBQ {
  padding: 3px 0.6em;
  float: right;
  font-weight: normal;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 6px; }
  .BoatKeyInformation_BoatKeyInformationLabel--smaller__1GgF5 {
    font-size: 0.65em;
    line-height: 1;
    padding: 0.5em 1em;
    border-radius: 5px; }

.BoatKeyInformation_BoatKeyInformationLabelMedium__3C2lF {
  padding: 0 0.3em;
  font-size: 14px;
  height: 24px;
  line-height: 19px;
  padding: 0.22em 0.3em;
  border-radius: 6px;
  font-weight: bold;
  align-items: center;
  align-items: center; }

.BoatKeyInformation_BoatKeyInformationSubtext__3p2P- {
  font-size: 12px; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SearchInput_Button__IyBWx, .SearchInput_ButtonRenter__2AVJc, .SearchInput_ButtonOwner__1XrOn, .SearchInput_ButtonYellow__3f7_o, .SearchInput_ButtonOwnerOutline__1EoXI, .SearchInput_ButtonCaptain__31DBs {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SearchInput_ButtonRenter__2AVJc {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SearchInput_ButtonRenter__2AVJc:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SearchInput_ButtonRenter__2AVJc:active {
    background: #083170;
    border: 1px solid #083170; }

.SearchInput_ButtonOwner__1XrOn {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SearchInput_ButtonOwner__1XrOn:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SearchInput_ButtonOwner__1XrOn:active {
    background: #154353;
    border: 1px solid #154353; }

.SearchInput_ButtonYellow__3f7_o {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SearchInput_ButtonYellow__3f7_o:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SearchInput_ButtonYellow__3f7_o:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SearchInput_ButtonOwnerOutline__1EoXI {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SearchInput_ButtonOwnerOutline__1EoXI:hover {
    background: #154353;
    color: white; }

.SearchInput_ButtonCaptain__31DBs {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SearchInput_ButtonCaptain__31DBs:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SearchInput_ButtonCaptain__31DBs:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SearchInput_ButtonSmall__3OzIc {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.SearchInput_SearchInput__Sx7rP {
  transition: all 0.3s ease;
  position: relative;
  z-index: 14;
  visibility: visible;
  opacity: 1;
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0; }
  @media only screen and (max-width: 640px) {
    .SearchInput_SearchInput__Sx7rP {
      visibility: hidden;
      opacity: 0;
      width: 0; }
      .SearchInput_SearchInput__Sx7rP.SearchInput_expanded__31pK6 {
        max-width: 90% !important;
        transition: max-width 0.5s ease;
        opacity: 1;
        visibility: visible;
        z-index: 14;
        width: 100% !important; }
        .SearchInput_SearchInput__Sx7rP.SearchInput_expanded__31pK6 .SearchInput_SearchInputContainer__1RCSv {
          width: 100% !important; } }

.SearchInput_SearchInputContainer__1RCSv {
  position: relative;
  display: inline-block; }

.SearchInput_SearchInputElement__3eZYg {
  padding: 0.9em;
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.9em;
  padding-left: 1em;
  padding-right: 2.5em;
  border-radius: 4px;
  border: 0;
  box-sizing: border-box !important;
  display: block !important;
  width: 100% !important;
  flex-basis: auto !important; }
  @media only screen and (min-width: 640px) {
    .SearchInput_SearchInputElement__3eZYg {
      width: 300px !important; } }
  .SearchInput_SearchInputElement__3eZYg.SearchInput_pac-target-input__21UEr:not(:-webkit-autofill) {
    -webkit-animation-name: SearchInput_endBrowserAutofill__23kma;
            animation-name: SearchInput_endBrowserAutofill__23kma; }
  .SearchInput_SearchInputElement__3eZYg::-moz-placeholder {
    color: #fff !important;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .SearchInput_SearchInputElement__3eZYg:-ms-input-placeholder {
    color: #fff !important;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .SearchInput_SearchInputElement__3eZYg::-ms-input-placeholder {
    color: #fff !important;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .SearchInput_SearchInputElement__3eZYg::placeholder {
    color: #fff !important;
    transition: all 0.3s ease; }

.SearchInput_SearchFormPrefix__Yagn0 {
  font-family: "BoasetterV4";
  font-size: 1em;
  font-size: 1.4em;
  color: #fff;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  margin-right: 0.5em; }
  @media only screen and (max-width: 640px) {
    .SearchInput_SearchFormPrefix__Yagn0 {
      display: none; } }

@-webkit-keyframes SearchInput_endBrowserAutofill__23kma {
  0% { }
  to { } }

@keyframes SearchInput_endBrowserAutofill__23kma {
  0% { }
  to { } }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatMap_Button__FR23q, .BoatMap_ButtonRenter__RYDy8, .BoatMap_ButtonOwner__3vs_f, .BoatMap_ButtonYellow__1VqU-, .BoatMap_ButtonOwnerOutline__3e6qE, .BoatMap_ButtonCaptain__mvEqT {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatMap_ButtonRenter__RYDy8 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatMap_ButtonRenter__RYDy8:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatMap_ButtonRenter__RYDy8:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatMap_ButtonOwner__3vs_f {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatMap_ButtonOwner__3vs_f:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatMap_ButtonOwner__3vs_f:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatMap_ButtonYellow__1VqU- {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatMap_ButtonYellow__1VqU-:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatMap_ButtonYellow__1VqU-:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatMap_ButtonOwnerOutline__3e6qE {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatMap_ButtonOwnerOutline__3e6qE:hover {
    background: #154353;
    color: white; }

.BoatMap_ButtonCaptain__mvEqT {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatMap_ButtonCaptain__mvEqT:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatMap_ButtonCaptain__mvEqT:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatMap_ButtonSmall__m0eY3 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatMap_BoatMapWrapper__lvT1c {
  position: relative; }

.BoatMap_BoatMap__19K-b {
  width: 100%;
  height: 440px;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  position: relative; }
  @media only screen and (max-width: 640px) {
    .BoatMap_BoatMap__19K-b {
      height: 100vh; } }
  .BoatMap_BoatMap__19K-b .BoatMap_gm-style__3krF8 {
    border-radius: 16px !important;
    overflow: hidden !important; }

.BoatMap_BoatMapZoomControls__1mPSD {
  display: flex;
  flex-direction: column !important;
  margin-right: 0.5em; }

.BoatMap_ZoomControls__3vAj8 .BoatMap_ZoomButton__1mW7u {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1em; }
  .BoatMap_ZoomControls__3vAj8 .BoatMap_ZoomButton__1mW7u img {
    pointer-events: none; }
  .BoatMap_ZoomControls__3vAj8 .BoatMap_ZoomButton__1mW7u i:before {
    background: #fff;
    border-radius: 50%; }

.BoatMap_SearchZoomControls__2iGdZ {
  flex-direction: column !important;
  display: flex !important;
  position: absolute;
  right: 10px;
  z-index: 2; }
  .BoatMap_SearchZoomControls__2iGdZ.BoatMap_SearchZoomControlsTop__2gT3- {
    top: 20px; }
  .BoatMap_SearchZoomControls__2iGdZ.BoatMap_SearchZoomControlsBottom__PsM0l {
    bottom: 50px; }
  .BoatMap_SearchZoomControls__2iGdZ .BoatMap_SearchZoomButton__29ekR {
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 16px;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    background: #fff;
    border-radius: 50%; }
    .BoatMap_SearchZoomControls__2iGdZ .BoatMap_SearchZoomButton__29ekR img {
      pointer-events: none;
      max-width: 100%;
      height: auto; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.MobileMapToggle_Button__3Z8-g, .MobileMapToggle_ButtonRenter__1b88e, .MobileMapToggle_ButtonOwner__AVBPp, .MobileMapToggle_ButtonYellow__3QysH, .MobileMapToggle_ButtonOwnerOutline__3Bas_, .MobileMapToggle_ButtonCaptain__13wpq {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.MobileMapToggle_ButtonRenter__1b88e {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .MobileMapToggle_ButtonRenter__1b88e:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .MobileMapToggle_ButtonRenter__1b88e:active {
    background: #083170;
    border: 1px solid #083170; }

.MobileMapToggle_ButtonOwner__AVBPp {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .MobileMapToggle_ButtonOwner__AVBPp:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .MobileMapToggle_ButtonOwner__AVBPp:active {
    background: #154353;
    border: 1px solid #154353; }

.MobileMapToggle_ButtonYellow__3QysH {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .MobileMapToggle_ButtonYellow__3QysH:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .MobileMapToggle_ButtonYellow__3QysH:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.MobileMapToggle_ButtonOwnerOutline__3Bas_ {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .MobileMapToggle_ButtonOwnerOutline__3Bas_:hover {
    background: #154353;
    color: white; }

.MobileMapToggle_ButtonCaptain__13wpq {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .MobileMapToggle_ButtonCaptain__13wpq:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .MobileMapToggle_ButtonCaptain__13wpq:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.MobileMapToggle_ButtonSmall__BtEpn {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.MobileMapToggle_MobileMapButton__3SOh7 {
  position: fixed;
  font-style: normal;
  text-transform: uppercase;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  background: #0751c2;
  color: #fdfdfd;
  min-width: 108px;
  padding: 15px;
  border-radius: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatSpecificationPanel_Button__295uf, .BoatSpecificationPanel_ButtonRenter__2x8ek, .BoatSpecificationPanel_ButtonOwner__RljVR, .BoatSpecificationPanel_ButtonYellow__27Ncc, .BoatSpecificationPanel_ButtonOwnerOutline__3ur2N, .BoatSpecificationPanel_ButtonCaptain__3WDoD {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatSpecificationPanel_ButtonRenter__2x8ek {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatSpecificationPanel_ButtonRenter__2x8ek:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatSpecificationPanel_ButtonRenter__2x8ek:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatSpecificationPanel_ButtonOwner__RljVR {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatSpecificationPanel_ButtonOwner__RljVR:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatSpecificationPanel_ButtonOwner__RljVR:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatSpecificationPanel_ButtonYellow__27Ncc {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatSpecificationPanel_ButtonYellow__27Ncc:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatSpecificationPanel_ButtonYellow__27Ncc:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatSpecificationPanel_ButtonOwnerOutline__3ur2N {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatSpecificationPanel_ButtonOwnerOutline__3ur2N:hover {
    background: #154353;
    color: white; }

.BoatSpecificationPanel_ButtonCaptain__3WDoD {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatSpecificationPanel_ButtonCaptain__3WDoD:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatSpecificationPanel_ButtonCaptain__3WDoD:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatSpecificationPanel_ButtonSmall__1LLoG {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatSpecificationPanel_BoatSpecificationPanelWrapper__3BoYp {
  padding: 2em;
  border-radius: 9px;
  background-color: #fff;
  position: relative;
  border: solid 1px #dbdfe5; }

.BoatSpecificationPanel_SpecificationHeading__2XoXU {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.333333;
  margin-bottom: 1em; }
  @media only screen and (max-width: 640px) and (min-width: 320px) {
    .BoatSpecificationPanel_SpecificationHeading__2XoXU {
      font-weight: 600;
      font-size: 1.25em;
      line-height: 1.4; } }

.BoatSpecificationPanel_SpecificationsWrapper__2P-zm {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0 calc(-0.5 * 24px); }

.BoatSpecificationPanel_Cell__31Utb {
  padding: 0 calc(0.5 * 24px);
  margin-bottom: 1em;
  flex: 0 0 100%; }
  @media only screen and (min-width: 640px) {
    .BoatSpecificationPanel_Cell__31Utb {
      flex-basis: auto !important;
      width: 50% !important; } }

.BoatSpecificationPanel_SpecificationValue__2Q0gX {
  font-weight: 700;
  margin-left: 1em; }

.BoatSpecificationPanel_FeaturesPanelDivider__oW3u_ {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #dbdfe5;
  margin: 0;
  margin-bottom: 28px;
  margin-top: 16px;
  width: 100%; }

.BoatSpecificationPanel_FeauturesPanelLink__2Cdnc {
  color: #0751c2;
  padding: 0 calc(0.5 * 24px); }

@charset "UTF-8";
/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatCard_Button__13FiH, .BoatCard_ButtonRenter__-VFn4, .BoatCard_ButtonOwner__11XCs, .BoatCard_ButtonYellow__2poVQ, .BoatCard_ButtonOwnerOutline__1ynJ1, .BoatCard_ButtonCaptain__1H7Dv {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatCard_ButtonRenter__-VFn4 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatCard_ButtonRenter__-VFn4:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatCard_ButtonRenter__-VFn4:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatCard_ButtonOwner__11XCs {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatCard_ButtonOwner__11XCs:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatCard_ButtonOwner__11XCs:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatCard_ButtonYellow__2poVQ {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatCard_ButtonYellow__2poVQ:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatCard_ButtonYellow__2poVQ:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatCard_ButtonOwnerOutline__1ynJ1 {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatCard_ButtonOwnerOutline__1ynJ1:hover {
    background: #154353;
    color: white; }

.BoatCard_ButtonCaptain__1H7Dv {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatCard_ButtonCaptain__1H7Dv:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatCard_ButtonCaptain__1H7Dv:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatCard_ButtonSmall__3dF7R {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatCard_disableClick__3t4VS {
  cursor: not-allowed !important; }
  .BoatCard_disableClick__3t4VS * {
    pointer-events: none;
    cursor: not-allowed !important; }

.BoatCard_BoatCard__2ul_2 {
  padding: 0.5em 0;
  margin: 0 0 1em 0;
  position: relative;
  font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardWrapper__2Kx-L {
    border-radius: 14px;
    overflow: hidden;
    border: 1px solid #dbdfe5;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.05);
    display: block;
    color: #17233c !important;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: all 0.2 ease; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardHeader__g02lX {
    position: relative;
    margin: 0;
    width: 100%;
    height: auto;
    overflow: visible;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardHeader__g02lX.BoatCard_hovercard__lXUR0 {
      height: 163px;
      font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 2; }
    .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button__13FiH, .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_ButtonRenter__-VFn4, .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_ButtonOwner__11XCs, .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_ButtonYellow__2poVQ, .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_ButtonOwnerOutline__1ynJ1, .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFavorite__1wd00 .BoatCard_ButtonCaptain__1H7Dv {
      border: 1px solid #8e9697 !important; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardContent__3I9OH {
    padding: 0.5em 1em 1em 1em;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardTag__1wlcr {
    position: absolute;
    right: 1em;
    bottom: 1em;
    z-index: 2;
    background: white;
    border-radius: 6px;
    text-align: center;
    font-weight: 800;
    padding: 0.4em;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardInstant__3tri_ {
    margin-right: 0.5em;
    height: 20px; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardTitle__35zBy {
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    width: 100%;
    margin: 0 0.3em 0 0;
    min-height: 50px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #17233c; }
  .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFeatures__22ys6 span {
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0 0.5em 0 0;
    padding: 0 1em 0 0;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #17233c; }
    .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFeatures__22ys6 span:after {
      content: "\B7";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardFeatures__22ys6 span:last-child:after {
      margin: 0;
      padding: 0;
      content: none; }

.BoatCard_BoatCardFavoriteButton__2Mlzv {
  width: 30px;
  height: 30px;
  border: 1px solid #8e9697 !important;
  border-radius: 50%;
  position: relative;
  color: #17233c;
  background: #fff;
  padding: 0;
  min-width: auto;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  line-height: normal;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875em;
  align-items: center; }

.BoatCard_hovercard-wrap__3bUrT .BoatCard_BoatCardContent__3I9OH {
  padding: 13px 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .BoatCard_hovercard-wrap__3bUrT .BoatCard_BoatCardContent__3I9OH p {
    font-weight: 600; }
  .BoatCard_hovercard-wrap__3bUrT .BoatCard_BoatCardContent__3I9OH .BoatCard_BoatCardTitle__35zBy {
    min-height: 40px;
    margin: 0; }

.BoatCard_BoatCardContentWrapper__3od4W {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
  margin-bottom: 10px; }

.BoatCard_BoatCardContentLocation__boxvc {
  font-size: 12px;
  font-weight: 600;
  font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #8e9697;
  text-transform: uppercase;
  flex: 1 1;
  flex-shrink: 1;
  font-size: 0.75em;
  line-height: 1.5;
  margin-bottom: 0; }

@media only screen and (min-width: 960px) {
  .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr {
    width: 100% !important;
    margin-right: 0em !important;
    height: 195px;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardHeader__g02lX {
      width: 30%;
      min-width: 300px;
      height: 100%;
      font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardFavorite__1wd00 {
      right: 1em; }
    .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardWrapper__2Kx-L {
      flex-direction: row;
      display: flex; }
    .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardContent__3I9OH {
      flex-grow: 1;
      height: 195px;
      padding: 21px;
      font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardTag__1wlcr {
      top: auto;
      bottom: 1em;
      font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }
      .BoatCard_BoatCard__2ul_2.BoatCard_BoatCardHorizontal__yD9nr .BoatCard_BoatCardTag__1wlcr span {
        font-size: 16;
        font-weight: 900;
        line-height: 20px;
        color: black; }
  .BoatCard_BoatCardHorizontal__yD9nr .BoatCard_carousel__eQjA_ .BoatCard_slider-wrapper__1MChg li {
    overflow: hidden; } }

@media only screen and (max-width: 640px) {
  .BoatCard_BoatCard__2ul_2 {
    padding-bottom: 1em;
    padding-top: 0; }
    .BoatCard_BoatCard__2ul_2 .BoatCard_BoatCardHorizontal__yD9nr {
      border-radius: 0px !important; }
    .BoatCard_BoatCard__2ul_2 li.BoatCard_slide__-fnYG,
    .BoatCard_BoatCard__2ul_2 li.BoatCard_slide__-fnYG div {
      border-radius: 0px !important; } }

.BoatCard_BoatcardFeatures__1QJFe {
  line-height: 15px; }

.BoatCard_force100w__o-Z7i {
  width: 100%; }

#BoatCard_SimilarBoats__1cy7l .BoatCard_slick-dots__3jzUy {
  bottom: 0; }

@media only screen and (max-width: 640px) {
  #BoatCard_SimilarBoats__1cy7l {
    margin-bottom: 2em; } }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_carousel-icon__J_AEc {
  text-align: center; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button__13FiH.BoatCard_Button--circle__2GGVF, .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button--circle__2GGVF.BoatCard_ButtonRenter__-VFn4, .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button--circle__2GGVF.BoatCard_ButtonOwner__11XCs, .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button--circle__2GGVF.BoatCard_ButtonYellow__2poVQ, .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button--circle__2GGVF.BoatCard_ButtonOwnerOutline__1ynJ1, .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFavorite__1wd00 .BoatCard_Button--circle__2GGVF.BoatCard_ButtonCaptain__1H7Dv {
  width: 36px;
  height: 36px; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardContent__3I9OH {
  padding: 16px 24px 22px; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFeatures__22ys6 span {
  font-size: 14px;
  font-weight: 400; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardFeatures__22ys6 span:after {
  font-weight: bold;
  font-size: 20px; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardTitle__35zBy {
  font-size: 16px;
  margin-bottom: 12px; }

.BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardReviewWrapper__1-StN {
  font-size: 12px; }

@media only screen and (max-width: 640px) {
  .BoatCard_SimilarBoatCard__3tsrE .BoatCard_BoatCardHeader__g02lX {
    height: 180px;
    overflow: inherit; } }

@media only screen and (max-width: 640px) {
  .BoatCard_SimilarBoatCard__3tsrE {
    max-width: 274px; }
    .BoatCard_SimilarBoatCard__3tsrE .BoatCard_carousel-root__viA51 {
      outline: none;
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%; } }

.BoatCard_BoatCard__2ul_2 .BoatCard_carousel__eQjA_ .BoatCard_control-dots__1tyX6 {
  bottom: 0 !important;
  top: inherit !important;
  text-align: center; }

.BoatCard_CardIsLoading__347I1 {
  transition: all 0.5s ease;
  color: transparent !important;
  background-color: #8e9697 !important;
  background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, 0.3) 50%, transparent 66%) !important;
  background: #f9fafe 0% no-repeat padding-box !important;
  background-size: 300% 100% !important;
  -webkit-animation: BoatCard_shine__32c7D 2s infinite;
          animation: BoatCard_shine__32c7D 2s infinite; }

@-webkit-keyframes BoatCard_shine__32c7D {
  0% {
    background-position: right; } }

@keyframes BoatCard_shine__32c7D {
  0% {
    background-position: right; } }
  .BoatCard_CardIsLoading__347I1 img {
    visibility: hidden; }

.BoatCard_GlobalTitle__SpnOq {
  min-height: 33px !important; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SearchCalendar_Button__2oiyH, .SearchCalendar_ButtonRenter__2jIUj, .SearchCalendar_ButtonOwner__1EH1p, .SearchCalendar_ButtonYellow__1tYJ-, .SearchCalendar_ButtonOwnerOutline__2ushC, .SearchCalendar_ButtonCaptain__3Oa65 {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SearchCalendar_ButtonRenter__2jIUj {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SearchCalendar_ButtonRenter__2jIUj:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SearchCalendar_ButtonRenter__2jIUj:active {
    background: #083170;
    border: 1px solid #083170; }

.SearchCalendar_ButtonOwner__1EH1p {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SearchCalendar_ButtonOwner__1EH1p:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SearchCalendar_ButtonOwner__1EH1p:active {
    background: #154353;
    border: 1px solid #154353; }

.SearchCalendar_ButtonYellow__1tYJ- {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SearchCalendar_ButtonYellow__1tYJ-:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SearchCalendar_ButtonYellow__1tYJ-:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SearchCalendar_ButtonOwnerOutline__2ushC {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SearchCalendar_ButtonOwnerOutline__2ushC:hover {
    background: #154353;
    color: white; }

.SearchCalendar_ButtonCaptain__3Oa65 {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SearchCalendar_ButtonCaptain__3Oa65:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SearchCalendar_ButtonCaptain__3Oa65:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SearchCalendar_ButtonSmall__25Ubs {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.SearchCalendar_CalendarNav__uUpW3 {
  cursor: pointer;
  color: #17233c; }
  .SearchCalendar_CalendarNav__uUpW3:focus {
    outline: 0; }

.SearchCalendar_CalendarDayOfWeek__1xxj9 {
  width: calc(100% / 7);
  padding: 0.5em;
  text-align: center;
  color: #5e696a; }

.SearchCalendar_CalendarDay__1-dKv {
  width: calc(100% / 7);
  line-height: 34px;
  font-weight: 600px;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  border-radius: 6px; }
  .SearchCalendar_CalendarDay__1-dKv:hover {
    background-color: #0751c2;
    color: white; }

.SearchCalendar_CalendarWrapper__354cO {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  display: flex; }

.SearchCalendar_CalendarHeaderWrapper__1Cojn {
  padding: 1em; }

.SearchCalendar_CalendarWrappedRow__1y2SC {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid #dbdfe5; }

.SearchCalendar_HeaderWrapper__DPvpR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em; }

.SearchCalendar_MonthLabel__2Zyex {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center; }

.SearchCalendar_CalendarDate__33hxy {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px; }

.SearchCalendar_CalendarDayInactive__2W7MH {
  color: #8e9697;
  cursor: default;
  background-color: white;
  pointer-events: none; }

.SearchCalendar_CalendarDayEmpty__P_6xu {
  border: 0;
  cursor: default;
  background: #fff;
  pointer-events: none; }

.SearchCalendar_isSelected__3F1p1,
.SearchCalendar_isRangeSelected__2QDbc {
  background-color: #0751c2; }
  .SearchCalendar_isSelected__3F1p1,
  .SearchCalendar_isSelected__3F1p1 > .SearchCalendar_Calendar-price__3AyE7,
  .SearchCalendar_isRangeSelected__2QDbc,
  .SearchCalendar_isRangeSelected__2QDbc > .SearchCalendar_Calendar-price__3AyE7 {
    color: #fff; }
  .SearchCalendar_isSelected__3F1p1:hover,
  .SearchCalendar_isRangeSelected__2QDbc:hover {
    background-color: #0751c2; }

.SearchCalendar_AngleIcon__13E2k {
  width: 10px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  height: 100%; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick_slick-loading__1wd2V .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick_slick-initialized__KtWdO .slick-slide {
    display: block; }
  .slick_slick-loading__1wd2V .slick-slide {
    visibility: hidden; }
  .slick_slick-vertical__3CVML .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@charset "UTF-8";
/* Slider */
/* Icons */
/* Arrows */
 {
  /* Dots */ }
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    .slick-prev:hover, .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .slick-prev:hover:before, .slick-prev:focus:before,
      .slick-next:hover:before,
      .slick-next:focus:before {
        opacity: 1; }
    .slick-prev:before,
    .slick-next:before {
      font-family: "AvenirNext";
      font-size: 20px;
      line-height: 1;
      color: #0751c2;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .slick-prev {
    left: -25px; }
    [dir="rtl"] .slick-prev {
      left: auto;
      right: -25px; }
    .slick-prev:before {
      content: "\2190"; }
      [dir="rtl"] .slick-prev:before {
        content: "\2192"; }
  .slick-next {
    right: -25px; }
    [dir="rtl"] .slick-next {
      left: -25px;
      right: auto; }
    .slick-next:before {
      content: "\2192"; }
      [dir="rtl"] .slick-next:before {
        content: "\2190"; }
  .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .slick-dots {
    position: absolute;
    bottom: 15px;
    list-style: none;
    display: block;
    text-align: left;
    padding: 0;
    margin: 0;
    width: 100%; }
    .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 1px;
      padding: 0;
      cursor: pointer; }
      .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .slick-dots li button:hover, .slick-dots li button:focus {
          outline: none; }
          .slick-dots li button:hover:before, .slick-dots li button:focus:before {
            opacity: 1; }
        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "\2022";
          width: 20px;
          height: 20px;
          font-family: "AvenirNext";
          font-size: 24px;
          line-height: 20px;
          text-align: center;
          color: #dbdfe5;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .slick-dots li.slick-active button:before {
        color: #8e9697;
        opacity: 0.75; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatPhotoGallery_Button__2dDQH, .BoatPhotoGallery_ButtonRenter__3K6cZ, .BoatPhotoGallery_ButtonOwner__3Vgqk, .BoatPhotoGallery_ButtonYellow__1SFG6, .BoatPhotoGallery_ButtonOwnerOutline__2CaGS, .BoatPhotoGallery_ButtonCaptain__2sYMd {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatPhotoGallery_ButtonRenter__3K6cZ {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatPhotoGallery_ButtonRenter__3K6cZ:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatPhotoGallery_ButtonRenter__3K6cZ:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatPhotoGallery_ButtonOwner__3Vgqk {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatPhotoGallery_ButtonOwner__3Vgqk:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatPhotoGallery_ButtonOwner__3Vgqk:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatPhotoGallery_ButtonYellow__1SFG6 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatPhotoGallery_ButtonYellow__1SFG6:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatPhotoGallery_ButtonYellow__1SFG6:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatPhotoGallery_ButtonOwnerOutline__2CaGS {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatPhotoGallery_ButtonOwnerOutline__2CaGS:hover {
    background: #154353;
    color: white; }

.BoatPhotoGallery_ButtonCaptain__2sYMd {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatPhotoGallery_ButtonCaptain__2sYMd:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatPhotoGallery_ButtonCaptain__2sYMd:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatPhotoGallery_ButtonSmall__DsWHO {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatPhotoGallery_BoatPhotoGallery__3-xoL {
  position: relative;
  width: 100%; }

.BoatPhotoGallery_PhotosGalleryNoPreviews__113hT {
  height: 100%; }
  .BoatPhotoGallery_PhotosGalleryNoPreviews__113hT a {
    height: 100%; }
    .BoatPhotoGallery_PhotosGalleryNoPreviews__113hT a > div {
      height: 100%;
      background-position: center right; }

.BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r {
  width: 100vw;
  overflow: hidden;
  display: none; }
  .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r .BoatPhotoGallery_slick-dots__VJiva {
    bottom: 20px;
    margin-left: 12px;
    text-align: left; }
    .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r .BoatPhotoGallery_slick-dots__VJiva li button:before {
      color: #b8bec4; }
    .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r .BoatPhotoGallery_slick-dots__VJiva li.BoatPhotoGallery_slick-active__1wyDg button:before {
      color: #fff !important;
      opacity: 1; }
  @media only screen and (min-width: 960px) {
    .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r {
      display: none; } }
  @media only screen and (max-width: 960px) {
    .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r {
      display: none; } }
  @media only screen and (max-width: 640px) {
    .BoatPhotoGallery_BoatPhotoGalleryCarouselMobile__3XW9r {
      display: block; } }

.BoatPhotoGallery_w100___bWUV {
  width: 100%; }

.BoatPhotoGallery_BoatGallerySliderOverlayWrapper__2pN4p {
  position: relative; }

.BoatPhotoGallery_BoatGallerySliderOverlay__3MJaV {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .BoatPhotoGallery_BoatGallerySliderOverlay__3MJaV:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1; }

.BoatPhotoGallery_CarouselSpan__3Sth2 {
  padding-left: 10px;
  line-height: 30px;
  color: #ffffff; }

.BoatPhotoGallery_BoatGallerySliderImageWrapper__z04Lw {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%; }

.BoatPhotoGallery_BoatGallerySliderImageWrapper__z04Lw:before {
  padding-bottom: 60%;
  content: "";
  display: block;
  width: 100%; }

.BoatPhotoGallery_BoatGallerySliderImg__3dAeT {
  position: absolute;
  border-radius: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }
  @media only screen and (max-width: 640px) {
    .BoatPhotoGallery_BoatGallerySliderImg__3dAeT {
      background-size: cover;
      background-position: center;
      width: 100vw;
      border-radius: 0; } }

.BoatPhotoGallery_BoatPhotoGalleryPreviewsWrapper__2T8q_ {
  position: relative; }
  @media only screen and (max-width: 640px) {
    .BoatPhotoGallery_BoatPhotoGalleryPreviewsWrapper__2T8q_ .BoatPhotoGallery_u-sm-hide__Akb_j {
      display: none !important; } }

.BoatPhotoGallery_PhotosGalleryCoverPhoto__a5vI9 {
  border-radius: 9px; }

.BoatPhotoGallery_BoatCoverPhoto__376IG {
  padding-bottom: calc(2 / 3 * 100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.BoatPhotoGallery_BoatPhotoGallerySecondPhoto__2t18I {
  position: absolute;
  width: 157px;
  bottom: 20px;
  left: 20px;
  border: 1px solid #fff; }
  .BoatPhotoGallery_BoatPhotoGallerySecondPhoto__2t18I .BoatPhotoGallery_PhotosGalleryAllPhotos__dPlaf {
    border-radius: 0px; }
  .BoatPhotoGallery_BoatPhotoGallerySecondPhoto__2t18I .BoatPhotoGallery_PhotosGalleryCoverPhoto__a5vI9 {
    border-radius: 0px; }

.BoatPhotoGallery_BoatPhotoGalleryPreview__xQsuu {
  cursor: pointer;
  display: block;
  position: relative; }

.BoatPhotoGallery_BestPhotoWrapper__2kG-R {
  cursor: pointer;
  display: block;
  margin-bottom: 24px; }

.BoatPhotoGallery_PhotosGalleryAllPhotos__dPlaf {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 18%;
  text-align: center;
  background: #3c3b3bbd;
  color: white;
  border-radius: 9px; }

.BoatPhotoGallery_PhotosGalleryIcon__2nqd8 {
  font-size: 28px !important; }

.BoatPhotoGallery_BoatPhotoGalleryPreviewThumbsWrapper__3lJnD {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  margin: 0 calc(-0.5 * 25px); }

.BoatPhotoGallery_BoatPhotoGalleryPreviewThumbs__3_2RA {
  box-sizing: inherit;
  position: relative !important;
  padding: 0 calc(0.5 * 25px); }
  @media only screen and (min-width: 960px) {
    .BoatPhotoGallery_BoatPhotoGalleryPreviewThumbs__3_2RA {
      flex-basis: auto !important; } }
  @media only screen and (max-width: 960px) and (min-width: 640px) {
    .BoatPhotoGallery_BoatPhotoGalleryPreviewThumbs__3_2RA {
      width: calc(100% * 1 / 3) !important; } }

@media only screen and (min-width: 960px) {
  .BoatPhotoGallery_BoatPhotoGalleryPreviewThumbs4of12__3PM1j {
    width: calc(100% * 1 / 3) !important; } }

@media only screen and (min-width: 960px) {
  .BoatPhotoGallery_BoatPhotoGalleryPreviewThumbs1of2__10Dme {
    width: 50% !important; } }

.BoatPhotoGallery_PhotosGalleryFavorite__3QYjK {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none; }
  @media only screen and (min-width: 960px) {
    .BoatPhotoGallery_PhotosGalleryFavorite__3QYjK {
      display: none; } }
  @media only screen and (max-width: 960px) {
    .BoatPhotoGallery_PhotosGalleryFavorite__3QYjK {
      display: none; } }
  @media only screen and (max-width: 640px) {
    .BoatPhotoGallery_PhotosGalleryFavorite__3QYjK {
      display: flex !important; } }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatGallerySlider_Button__30QDg, .BoatGallerySlider_ButtonRenter__UAoy5, .BoatGallerySlider_ButtonOwner__1IOK3, .BoatGallerySlider_ButtonYellow__3npYt, .BoatGallerySlider_ButtonOwnerOutline__1UhaG, .BoatGallerySlider_ButtonCaptain__1AtxV {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatGallerySlider_ButtonRenter__UAoy5 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatGallerySlider_ButtonRenter__UAoy5:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatGallerySlider_ButtonRenter__UAoy5:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatGallerySlider_ButtonOwner__1IOK3 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatGallerySlider_ButtonOwner__1IOK3:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatGallerySlider_ButtonOwner__1IOK3:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatGallerySlider_ButtonYellow__3npYt {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatGallerySlider_ButtonYellow__3npYt:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatGallerySlider_ButtonYellow__3npYt:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatGallerySlider_ButtonOwnerOutline__1UhaG {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatGallerySlider_ButtonOwnerOutline__1UhaG:hover {
    background: #154353;
    color: white; }

.BoatGallerySlider_ButtonCaptain__1AtxV {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatGallerySlider_ButtonCaptain__1AtxV:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatGallerySlider_ButtonCaptain__1AtxV:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatGallerySlider_ButtonSmall__Lh1vQ {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatGallerySlider_BoatGallerySlider__3rILO {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9997;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  box-sizing: border-box; }

.BoatGallerySlider_BoatGallerySliderWrapper__1sFxu {
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: 1020px;
  overflow-y: scroll;
  background: #fff;
  padding-top: 1.5rem;
  padding-left: 2rem; }
  @media only screen and (max-width: 960px) {
    .BoatGallerySlider_BoatGallerySliderWrapper__1sFxu {
      width: 100%;
      width: 100%;
      align-items: center; } }

.BoatGallerySlider_BoatGallerySliderMain__CnhAP {
  height: calc(100% - 4rem - 70px);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: fixed;
  top: calc(30px + 2.5rem);
  background: #fff;
  width: calc(100% - 5rem - 220px);
  left: calc(220px + 3.5rem); }
  .BoatGallerySlider_BoatGallerySliderMain__CnhAP .BoatGallerySlider_slick-slider__luV5O.BoatGallerySlider_slick-initialized__3TUrR {
    height: 100%; }
  .BoatGallerySlider_BoatGallerySliderMain__CnhAP .BoatGallerySlider_slick-track__NBxvZ {
    height: 100%; }
  .BoatGallerySlider_BoatGallerySliderMain__CnhAP .BoatGallerySlider_slick-slide__7Yv98 > div {
    height: 100%; }
  .BoatGallerySlider_BoatGallerySliderMain__CnhAP .BoatGallerySlider_slick-list__3bvxi {
    height: 100%; }
  @media only screen and (max-width: 960px) {
    .BoatGallerySlider_BoatGallerySliderMain__CnhAP {
      left: 0;
      width: 100%; } }
  @media only screen and (max-width: 640px) {
    .BoatGallerySlider_BoatGallerySliderMain__CnhAP {
      width: 100%;
      margin-left: 0%; } }

.BoatGallerySlider_BoatGallerySliderThumbnails__dTckj {
  width: 220px;
  max-height: 95vh;
  overflow-x: hidden;
  overflow-y: scroll; }
  @media only screen and (max-width: 960px) {
    .BoatGallerySlider_BoatGallerySliderThumbnails__dTckj {
      display: none; } }

.BoatGallerySlider_BoatGallerySliderThumbnailsNoslider__2M-To {
  position: relative;
  top: 0;
  height: 100%; }
  .BoatGallerySlider_BoatGallerySliderThumbnailsNoslider__2M-To .BoatGallerySlider_slick-slider__luV5O {
    height: 100%; }
  .BoatGallerySlider_BoatGallerySliderThumbnailsNoslider__2M-To .BoatGallerySlider_slick-track__NBxvZ {
    height: 100% !important; }

.BoatGallerySlider_BoatGallerySliderPhotoItem__28DO6 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.BoatGallerySlider_BoatGallerySliderPhotoWrapper__nDeyn {
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center; }

.BoatGallerySlider_BoatGallerySliderPhoto__3WJdD {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  right: 0;
  top: 0; }

.BoatGallerySlider_BoatGallerySliderImageWrapper__3phFG {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%; }

.BoatGallerySlider_BoatGallerySliderImageWrapper__3phFG:before {
  padding-bottom: 60%;
  content: "";
  display: block;
  width: 100%; }

.BoatGallerySlider_BoatGallerySliderImg__3X-ZJ {
  position: absolute;
  border-radius: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }
  @media only screen and (max-width: 640px) {
    .BoatGallerySlider_BoatGallerySliderImg__3X-ZJ {
      background-size: cover;
      background-position: center;
      width: 100vw;
      border-radius: 0; } }

.BoatGallerySlider_BoatGallerySliderWrapperArrows__cRIib {
  height: 100%;
  width: 13%;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 0; }
  .BoatGallerySlider_BoatGallerySliderWrapperArrows__cRIib.BoatGallerySlider_next__1fSZL {
    right: 0; }
  @media only screen and (max-width: 1520px) {
    .BoatGallerySlider_BoatGallerySliderWrapperArrows__cRIib {
      width: 9%; } }
  @media only screen and (max-width: 1400px) {
    .BoatGallerySlider_BoatGallerySliderWrapperArrows__cRIib {
      width: 4%; } }

.BoatGallerySlider_BoatGallerySliderArrows__2badj {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  border-radius: 50% !important;
  border: none !important;
  outline: none !important;
  margin: 0px !important;
  padding: 0px !important;
  color: #222222 !important;
  cursor: pointer !important;
  touch-action: manipulation !important;
  background: transparent !important;
  transition: transform 0.25s ease 0s !important;
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999; }

.BoatGallerySlider_BoatGallerySliderArrows__2badj.BoatGallerySlider_next__1fSZL {
  right: 0; }

.BoatGallerySlider_BoatGallerySliderThumbImageActive__3ja-V {
  opacity: 0.5; }

.BoatGallerySlider_BoatGallerySliderArrowsNext__2rfiF {
  right: 50%;
  margin-right: 0px !important; }

.BoatGallerySlider_BoatGallerySliderArrowsPrevious__3jkTp {
  left: 50%;
  margin-left: 0px !important; }

.BoatGallerySlider_BoatGallerySliderArrows__2badj::before {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  background: white !important;
  border: 1px solid #0751c2 !important; }

.BoatGallerySlider_BoatGallerySliderArrows__2badj.BoatGallerySlider_disabled__2sYxx::before {
  border: 1px solid #dbdfe5 !important; }

.BoatGallerySlider_BoatGallerySliderArrows__2badj.BoatGallerySlider_disabled__2sYxx img {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.2; }

.BoatGallerySlider_BoatGallerySliderArrowsWrapper__xMdcw {
  position: relative; }

.BoatGallerySlider_BoatGallerySliderArrowsPlaceholder__2Fv71 {
  border: 0px !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(100%) !important;
  clip-path: inset(100%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0px !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important; }

.BoatGallerySlider_BoatGallerySliderThumbArrows__18YtO {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: 1px solid #0751c2;
  outline: none;
  width: 198px;
  height: 31px;
  background-color: #fff !important;
  margin-top: 16px; }

.BoatGallerySlider_BoatGallerySliderMain__CnhAP .BoatGallerySlider_slick-initialized__3TUrR .BoatGallerySlider_slick-slide__7Yv98 {
  display: flex;
  justify-content: center; }

.BoatGallerySlider_slick-current__XTJE4 .BoatGallerySlider_BoatGallerySliderThumbImageWrapper__KpjuD {
  opacity: 0.5; }

.BoatGallerySlider_BoatGallerySliderThumbImageWrapper__KpjuD {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer; }

.BoatGallerySlider_BoatGallerySliderThumbnails__dTckj .BoatGallerySlider_slick-slider__luV5O {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.BoatGallerySlider_BoatGallerySliderCounter__3dhaH {
  font-size: 18px;
  position: fixed;
  font-size: 18px;
  bottom: 1.5rem;
  width: calc(100% - 5rem - 220px);
  left: calc(220px + 3.5rem); }
  @media only screen and (max-width: 960px) {
    .BoatGallerySlider_BoatGallerySliderCounter__3dhaH {
      left: 21px;
      display: none; } }
  @media only screen and (max-width: 640px) {
    .BoatGallerySlider_BoatGallerySliderCounter__3dhaH {
      left: initial;
      top: 25%;
      display: none; } }

.BoatGallerySlider_BoatGallerySliderShareButtons__1-a4J {
  top: 1.5rem;
  width: calc(100% - 5rem - 220px);
  left: calc(220px + 3.5rem);
  font-size: 18px;
  position: fixed;
  font-size: 18px; }
  @media only screen and (max-width: 960px) {
    .BoatGallerySlider_BoatGallerySliderShareButtons__1-a4J {
      left: 21px;
      display: none; } }
  @media only screen and (max-width: 920px) {
    .BoatGallerySlider_BoatGallerySliderShareButtons__1-a4J {
      display: none; } }
  @media only screen and (max-width: 640px) {
    .BoatGallerySlider_BoatGallerySliderShareButtons__1-a4J {
      display: block !important;
      position: fixed;
      left: 15px;
      top: 15px; } }

.BoatGallerySlider_BoatGallerySliderClose__2cJFO {
  position: absolute;
  right: 21px;
  top: 1.5rem;
  z-index: 999; }
  .BoatGallerySlider_BoatGallerySliderClose__2cJFO i {
    color: #5e696a; }

.BoatGallerySlider_BoatGallerySliderOverlay__2Hyt0 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .BoatGallerySlider_BoatGallerySliderOverlay__2Hyt0:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1; }

.BoatGallerySlider_BoatGalleryCarouselMobile__2S74j {
  width: 100vw;
  overflow: hidden; }
  .BoatGallerySlider_BoatGalleryCarouselMobile__2S74j .BoatGallerySlider_slick-dots__10Jt9 {
    bottom: 20px;
    margin-left: 12px;
    text-align: left; }
    .BoatGallerySlider_BoatGalleryCarouselMobile__2S74j .BoatGallerySlider_slick-dots__10Jt9 li button:before {
      color: #b8bec4; }
    .BoatGallerySlider_BoatGalleryCarouselMobile__2S74j .BoatGallerySlider_slick-dots__10Jt9 li.BoatGallerySlider_slick-active__ehTNp button:before {
      color: #fff !important;
      opacity: 1; }

.BoatGallerySlider_PhotosGalleryNoPreviews__1TKlE .BoatGallerySlider_u-posRelative__24f1H {
  height: 100%; }

.BoatGallerySlider_PhotosGalleryNoPreviews__1TKlE a {
  height: 100%; }
  .BoatGallerySlider_PhotosGalleryNoPreviews__1TKlE a > div {
    height: 100%;
    background-position: center right; }

.BoatGallerySlider_react-transform-component__Lc4TG {
  height: 100% !important; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatImageList_Button__2jyR1, .BoatImageList_ButtonRenter__2OJxE, .BoatImageList_ButtonOwner__E4Zrf, .BoatImageList_ButtonYellow__8f6hA, .BoatImageList_ButtonOwnerOutline__3JDO2, .BoatImageList_ButtonCaptain__gxNkN {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatImageList_ButtonRenter__2OJxE {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatImageList_ButtonRenter__2OJxE:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatImageList_ButtonRenter__2OJxE:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatImageList_ButtonOwner__E4Zrf {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatImageList_ButtonOwner__E4Zrf:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatImageList_ButtonOwner__E4Zrf:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatImageList_ButtonYellow__8f6hA {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatImageList_ButtonYellow__8f6hA:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatImageList_ButtonYellow__8f6hA:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatImageList_ButtonOwnerOutline__3JDO2 {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatImageList_ButtonOwnerOutline__3JDO2:hover {
    background: #154353;
    color: white; }

.BoatImageList_ButtonCaptain__gxNkN {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatImageList_ButtonCaptain__gxNkN:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatImageList_ButtonCaptain__gxNkN:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatImageList_ButtonSmall__3TcMJ {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatImageList_BoatImageList__2v7sz {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9996;
  background: #ffffff;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding-top: 70px; }

.BoatImageList_BoatImageListWrapper__xx6Ve {
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  width: 85%;
  height: 85%;
  align-items: center; }
  @media only screen and (max-width: 960px) {
    .BoatImageList_BoatImageListWrapper__xx6Ve {
      width: 100%;
      width: 100%;
      align-items: center; } }

.BoatImageList_BoatImageListMain__1WDwY {
  width: 80%; }
  @media only screen and (max-width: 960px) {
    .BoatImageList_BoatImageListMain__1WDwY {
      width: 90%;
      margin-left: 5%; } }
  @media only screen and (max-width: 640px) {
    .BoatImageList_BoatImageListMain__1WDwY {
      width: 100%;
      margin-left: 0%; } }

.BoatImageList_BoatImageListThumbnails__2LdAB {
  width: 20%; }
  @media only screen and (max-width: 960px) {
    .BoatImageList_BoatImageListThumbnails__2LdAB {
      display: none; } }

.BoatImageList_BoatImageListThumbImageWrapper__2ux07 {
  display: flex !important;
  justify-content: center;
  margin-bottom: 1em; }

.BoatImageList_BoatImageListImageWrapper__1_Fz9 {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%; }

.BoatImageList_BoatImageListImageWrapper__1_Fz9:before {
  padding-bottom: 60%;
  content: "";
  display: block;
  width: 100%; }

.BoatImageList_BoatImageListArrows__27OmB {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  border-radius: 50% !important;
  border: none !important;
  outline: none !important;
  margin: 0px !important;
  padding: 0px !important;
  color: #222222 !important;
  cursor: pointer !important;
  touch-action: manipulation !important;
  background: transparent !important;
  transition: transform 0.25s ease 0s !important;
  position: fixed;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999; }

.BoatImageList_BoatImageListArrowsNext__2jN81 {
  right: 30px; }

.BoatImageList_BoatImageListArrowsPrevious__2dl8E {
  left: 30px; }

.BoatImageList_BoatImageListArrows__27OmB::before {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  background: white !important;
  border: 1px solid #0751c2 !important; }

.BoatImageList_BoatImageListArrowsWrapper__1nurN {
  position: relative; }

.BoatImageList_BoatImageListArrowsPlaceholder__1MYsj {
  border: 0px !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(100%) !important;
  clip-path: inset(100%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0px !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important; }

.BoatImageList_BoatImageListThumbArrows__1fON6 {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: 1px solid #0751c2;
  outline: none;
  width: 198px;
  height: 31px;
  background-color: #fff !important;
  margin-top: 16px; }

.BoatImageList_slick-current__2x3jz .BoatImageList_BoatImageListThumbImageWrapper__2ux07 {
  opacity: 0.5; }

.BoatImageList_BoatImageListThumbnails__2LdAB .BoatImageList_slick-slider__1JcpZ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.BoatImageList_BoatImageListCounter__A-duT {
  position: absolute;
  top: 16px;
  font-size: 18px; }
  @media only screen and (max-width: 960px) {
    .BoatImageList_BoatImageListCounter__A-duT {
      left: 21px; } }
  @media only screen and (max-width: 640px) {
    .BoatImageList_BoatImageListCounter__A-duT {
      left: initial;
      top: 25%; } }

.BoatImageList_BoatImageListClose__1m7N4 {
  position: absolute;
  right: 21px;
  top: 16px;
  z-index: 999; }

.BoatImageList_BoatImageListShareButtons__26Pzp {
  position: absolute;
  bottom: 0;
  right: 20px; }

.BoatImageList_BoatImageListHeader__1idrx {
  padding: 2em 3em 1.5em 3em;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
  background: #fff; }
  .BoatImageList_BoatImageListHeader__1idrx.BoatImageList_shadow__7GUWz {
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.15); }

.BoatImageList_BoatImageListGrid__1vMGc {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */
  margin: 0 calc(-0.5 * 25px);
  width: 100%; }

.BoatImageList_BoatImageListCell__uQFz7 {
  padding: 0 calc(0.6 * 25px);
  box-sizing: inherit;
  flex: 0 0 100%;
  /* 1 */
  margin-bottom: 1.5em; }
  .BoatImageList_BoatImageListCell__uQFz7 .BoatImageList_BoatPhotoSliderWrapper__3riW-:before {
    padding-bottom: calc(3 / 4.5 * 100%); }

.BoatImageList_BoatImageListImg__2_kqQ {
  position: absolute;
  border-radius: 8px;
  background-size: cover;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SearchMultiDaysCalendar_Button__2f8ZE, .SearchMultiDaysCalendar_ButtonRenter__2fPNQ, .SearchMultiDaysCalendar_ButtonOwner__WUR9G, .SearchMultiDaysCalendar_ButtonYellow__3cHI8, .SearchMultiDaysCalendar_ButtonOwnerOutline__209dk, .SearchMultiDaysCalendar_ButtonCaptain__2F6Ep {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SearchMultiDaysCalendar_ButtonRenter__2fPNQ {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SearchMultiDaysCalendar_ButtonRenter__2fPNQ:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SearchMultiDaysCalendar_ButtonRenter__2fPNQ:active {
    background: #083170;
    border: 1px solid #083170; }

.SearchMultiDaysCalendar_ButtonOwner__WUR9G {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SearchMultiDaysCalendar_ButtonOwner__WUR9G:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SearchMultiDaysCalendar_ButtonOwner__WUR9G:active {
    background: #154353;
    border: 1px solid #154353; }

.SearchMultiDaysCalendar_ButtonYellow__3cHI8 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SearchMultiDaysCalendar_ButtonYellow__3cHI8:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SearchMultiDaysCalendar_ButtonYellow__3cHI8:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SearchMultiDaysCalendar_ButtonOwnerOutline__209dk {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SearchMultiDaysCalendar_ButtonOwnerOutline__209dk:hover {
    background: #154353;
    color: white; }

.SearchMultiDaysCalendar_ButtonCaptain__2F6Ep {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SearchMultiDaysCalendar_ButtonCaptain__2F6Ep:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SearchMultiDaysCalendar_ButtonCaptain__2F6Ep:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SearchMultiDaysCalendar_ButtonSmall__1E9TF {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.SearchMultiDaysCalendar_CalendarNav__2usgv {
  cursor: pointer;
  color: #17233c; }
  .SearchMultiDaysCalendar_CalendarNav__2usgv:focus {
    outline: 0; }

.SearchMultiDaysCalendar_CalendarDayOfWeek__1zJup {
  width: calc(100% / 7);
  padding: 0.5em;
  text-align: center;
  color: #5e696a; }

.SearchMultiDaysCalendar_CalendarDay__3r1Cu {
  width: calc(100% / 7);
  line-height: 34px;
  font-weight: 600px;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 6px; }
  .SearchMultiDaysCalendar_CalendarDay__3r1Cu:hover {
    background-color: #1769e5 !important;
    color: white;
    transform: scale(1.1); }

.SearchMultiDaysCalendar_CalendarWrapper__27WIy {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  display: flex; }

.SearchMultiDaysCalendar_CalendarHeaderWrapper__2ACRF {
  padding: 1em; }

.SearchMultiDaysCalendar_CalendarWrappedRow__3rhs8 {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid #dbdfe5; }

.SearchMultiDaysCalendar_HeaderWrapper__10vqq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em; }

.SearchMultiDaysCalendar_MonthLabel__DceU2 {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center; }

.SearchMultiDaysCalendar_CalendarDate__3ZtZw {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px; }

.SearchMultiDaysCalendar_CalendarDayInactive__3oJbx {
  color: #8e9697;
  cursor: default;
  background-color: white;
  pointer-events: none; }

.SearchMultiDaysCalendar_CalendarDayEmpty__3x9wK {
  border: 0;
  cursor: default;
  background: #fff;
  pointer-events: none; }

.SearchMultiDaysCalendar_isSelected__31WlY,
.SearchMultiDaysCalendar_isRangeSelected__1czdH {
  background-color: #0751c2; }
  .SearchMultiDaysCalendar_isSelected__31WlY,
  .SearchMultiDaysCalendar_isSelected__31WlY > .SearchMultiDaysCalendar_Calendar-price__JFVCl,
  .SearchMultiDaysCalendar_isRangeSelected__1czdH,
  .SearchMultiDaysCalendar_isRangeSelected__1czdH > .SearchMultiDaysCalendar_Calendar-price__JFVCl {
    color: #fff; }
  .SearchMultiDaysCalendar_isSelected__31WlY:hover,
  .SearchMultiDaysCalendar_isRangeSelected__1czdH:hover {
    background-color: #0751c2; }

.SearchMultiDaysCalendar_AngleIcon__2Ql7e {
  width: 10px; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.BoatPhotoContainer_Button__2nZZb, .BoatPhotoContainer_ButtonRenter__RP9mK, .BoatPhotoContainer_ButtonOwner__1zPt3, .BoatPhotoContainer_ButtonYellow__YBPDE, .BoatPhotoContainer_ButtonOwnerOutline__1NoYs, .BoatPhotoContainer_ButtonCaptain__2jcoi {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.BoatPhotoContainer_ButtonRenter__RP9mK {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .BoatPhotoContainer_ButtonRenter__RP9mK:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .BoatPhotoContainer_ButtonRenter__RP9mK:active {
    background: #083170;
    border: 1px solid #083170; }

.BoatPhotoContainer_ButtonOwner__1zPt3 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .BoatPhotoContainer_ButtonOwner__1zPt3:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .BoatPhotoContainer_ButtonOwner__1zPt3:active {
    background: #154353;
    border: 1px solid #154353; }

.BoatPhotoContainer_ButtonYellow__YBPDE {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .BoatPhotoContainer_ButtonYellow__YBPDE:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .BoatPhotoContainer_ButtonYellow__YBPDE:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.BoatPhotoContainer_ButtonOwnerOutline__1NoYs {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .BoatPhotoContainer_ButtonOwnerOutline__1NoYs:hover {
    background: #154353;
    color: white; }

.BoatPhotoContainer_ButtonCaptain__2jcoi {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .BoatPhotoContainer_ButtonCaptain__2jcoi:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .BoatPhotoContainer_ButtonCaptain__2jcoi:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.BoatPhotoContainer_ButtonSmall__1Hir7 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.BoatPhotoContainer_BoatPhotoContainer__3UFj5 {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  height: 100%; }

.BoatPhotoContainer_BoatPhotoContainerWrapper__3jtrp {
  position: relative;
  width: 100%;
  z-index: 0;
  border-radius: 8px;
  overflow: hidden;
  max-height: 434px;
  height: 100%; }

.BoatPhotoContainer_BoatPhotoContainerWrapper__3jtrp:before {
  padding-bottom: calc(3 / 4 * 100%);
  content: "";
  display: block;
  width: 100%; }

.BoatPhotoContainer_BoatPhotoContainerWrapperPlaceholder__1AerL:before {
  padding-bottom: calc(3 / 4 * 50%); }
  @media only screen and (max-width: 640px) {
    .BoatPhotoContainer_BoatPhotoContainerWrapperPlaceholder__1AerL:before {
      padding-bottom: calc(3 / 4 * 100%); } }

.BoatPhotoContainer_BoatPhotoContainerContent__1eclv {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%; }

.BoatPhotoContainer_BoatPhotoContainerSlidesWrapper__33zIx {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.BoatPhotoContainer_BoatPhotoContainerSlides__2XiRd {
  width: 100%;
  height: 100%;
  white-space: nowrap; }

.BoatPhotoContainer_BoatPhotoContainerImageContainer__Nb-Zr {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 100%; }

.BoatPhotoContainer_BoatPhotoContainerImgWrapper__2YUsW {
  width: 100%;
  height: 100%; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SearchBookingCalendar_Button__QoXN2, .SearchBookingCalendar_ButtonRenter__36Mp9, .SearchBookingCalendar_ButtonOwner__1qj5w, .SearchBookingCalendar_ButtonYellow__2s2_Q, .SearchBookingCalendar_ButtonOwnerOutline__2cYSA, .SearchBookingCalendar_ButtonCaptain__KvYts {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SearchBookingCalendar_ButtonRenter__36Mp9 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SearchBookingCalendar_ButtonRenter__36Mp9:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SearchBookingCalendar_ButtonRenter__36Mp9:active {
    background: #083170;
    border: 1px solid #083170; }

.SearchBookingCalendar_ButtonOwner__1qj5w {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SearchBookingCalendar_ButtonOwner__1qj5w:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SearchBookingCalendar_ButtonOwner__1qj5w:active {
    background: #154353;
    border: 1px solid #154353; }

.SearchBookingCalendar_ButtonYellow__2s2_Q {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SearchBookingCalendar_ButtonYellow__2s2_Q:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SearchBookingCalendar_ButtonYellow__2s2_Q:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SearchBookingCalendar_ButtonOwnerOutline__2cYSA {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SearchBookingCalendar_ButtonOwnerOutline__2cYSA:hover {
    background: #154353;
    color: white; }

.SearchBookingCalendar_ButtonCaptain__KvYts {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SearchBookingCalendar_ButtonCaptain__KvYts:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SearchBookingCalendar_ButtonCaptain__KvYts:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SearchBookingCalendar_ButtonSmall__32pFZ {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.SearchBookingCalendar_CalendarNav__2cVxP {
  cursor: pointer;
  color: #17233c; }
  .SearchBookingCalendar_CalendarNav__2cVxP:focus {
    outline: 0; }

.SearchBookingCalendar_CalendarDayOfWeek__3oWSO {
  width: calc(100% / 7);
  padding: 0.5em;
  text-align: center;
  color: #5e696a; }

.SearchBookingCalendar_CalendarDay__3HNoa {
  width: calc(100% / 7);
  line-height: 34px;
  font-weight: 600px;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  min-height: 54px; }
  .SearchBookingCalendar_CalendarDay__3HNoa:hover {
    background-color: #0751c2;
    color: white; }
    .SearchBookingCalendar_CalendarDay__3HNoa:hover .SearchBookingCalendar_DayPrice__JYa31 {
      color: #dbdfe5; }

.SearchBookingCalendar_DayPrice__JYa31 {
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #8e9697;
  opacity: 1; }

.SearchBookingCalendar_CalendarClickDisabled__3SUff {
  cursor: not-allowed !important; }
  .SearchBookingCalendar_CalendarClickDisabled__3SUff * {
    pointer-events: none;
    cursor: not-allowed !important; }

.SearchBookingCalendar_CalendarWrapper__2b1bD {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  display: flex; }

.SearchBookingCalendar_CalendarHeaderWrapper__3U-Xo {
  padding: 1em; }

.SearchBookingCalendar_CalendarWrappedRow__2j1rh {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid #dbdfe5; }

.SearchBookingCalendar_HeaderWrapper__ObO7N {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em; }

.SearchBookingCalendar_MonthLabel__sIJy8 {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center; }

.SearchBookingCalendar_CalendarDate__36Jkj {
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column; }

.SearchBookingCalendar_CalendarDayEmpty__2w93J {
  border: 0;
  cursor: default;
  background: #fff;
  pointer-events: none; }

.SearchBookingCalendar_isSelected__2zhqy,
.SearchBookingCalendar_isRangeSelected__3D31r {
  background-color: #0751c2; }
  .SearchBookingCalendar_isSelected__2zhqy,
  .SearchBookingCalendar_isSelected__2zhqy > .SearchBookingCalendar_DayPrice__JYa31,
  .SearchBookingCalendar_isRangeSelected__3D31r,
  .SearchBookingCalendar_isRangeSelected__3D31r > .SearchBookingCalendar_DayPrice__JYa31 {
    color: #dbdfe5; }
  .SearchBookingCalendar_isSelected__2zhqy:hover,
  .SearchBookingCalendar_isRangeSelected__3D31r:hover {
    background-color: #0751c2; }

.SearchBookingCalendar_AngleIcon__2KWMU {
  width: 10px; }

.SearchBookingCalendar_dayFullBorderRadius__1t5wG {
  border-radius: 6px; }

.SearchBookingCalendar_dayLeftBorderRadius__JdlUI {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.SearchBookingCalendar_dayRightBorderRadius__3602S {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.SearchBookingCalendar_isInDurationHoveredDate__ieuCz {
  background: rgba(114, 212, 186, 0.6) !important;
  color: #17233c !important; }

.SearchBookingCalendar_isInDuration__2F8Xc {
  background: #72d4ba !important; }

.SearchBookingCalendar_isHovered__T5xdF {
  background: #0751c2 !important; }

.SearchBookingCalendar_DayPriceDisabled__24Dap {
  visibility: hidden;
  background-color: transparent !important; }

.SearchBookingCalendar_CalendarDayInactive__2aIJE {
  color: #8e9697;
  cursor: default;
  background-color: white !important;
  pointer-events: none; }

.SearchBookingCalendar_CalendarDayInactiveHover__2S9F4 {
  background-color: #bc350a !important;
  color: #17233c !important; }

.SearchBookingCalendar_isDisabledInCurrentDuration__2CKeK {
  cursor: not-allowed !important; }

.SearchBookingCalendar_blockedDateFont__1WU1M {
  font-weight: 400; }

.SearchBookingCalendar_IsStoneColor__iv47v {
  color: #f9fafe; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.SearchMapWithClusters_Button__vQBJP, .SearchMapWithClusters_ButtonRenter__1rctD, .SearchMapWithClusters_ButtonOwner__2tOer, .SearchMapWithClusters_ButtonYellow__2ahQM, .SearchMapWithClusters_ButtonOwnerOutline__3MwOk, .SearchMapWithClusters_ButtonCaptain__3SBJB {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.SearchMapWithClusters_ButtonRenter__1rctD {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .SearchMapWithClusters_ButtonRenter__1rctD:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .SearchMapWithClusters_ButtonRenter__1rctD:active {
    background: #083170;
    border: 1px solid #083170; }

.SearchMapWithClusters_ButtonOwner__2tOer {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .SearchMapWithClusters_ButtonOwner__2tOer:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .SearchMapWithClusters_ButtonOwner__2tOer:active {
    background: #154353;
    border: 1px solid #154353; }

.SearchMapWithClusters_ButtonYellow__2ahQM {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .SearchMapWithClusters_ButtonYellow__2ahQM:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .SearchMapWithClusters_ButtonYellow__2ahQM:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.SearchMapWithClusters_ButtonOwnerOutline__3MwOk {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .SearchMapWithClusters_ButtonOwnerOutline__3MwOk:hover {
    background: #154353;
    color: white; }

.SearchMapWithClusters_ButtonCaptain__3SBJB {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .SearchMapWithClusters_ButtonCaptain__3SBJB:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .SearchMapWithClusters_ButtonCaptain__3SBJB:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.SearchMapWithClusters_ButtonSmall__20cs2 {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.SearchMapWithClusters_GoogleMapSearch__2uOvI {
  width: 40vw;
  height: calc(100vh - 129px);
  overflow: hidden;
  position: fixed;
  bottom: 0;
  right: 0;
  top: 129px; }

.SearchMapWithClusters_dragSearchToggle__2VsU6 {
  position: absolute;
  font-weight: 500;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 14px;
  top: 10px;
  left: 10px;
  z-index: 2; }
  .SearchMapWithClusters_dragSearchToggle__2VsU6 > div {
    border-radius: 5px; }
  .SearchMapWithClusters_dragSearchToggle__2VsU6.SearchMapWithClusters_showBreadCrumbs__9nXFy {
    top: 50px; }

.SearchMapWithClusters_GoogleMapSearch__2uOvI.SearchMapWithClusters_stuck-top__q53iS .SearchMapWithClusters_dragSearchToggle__2VsU6.SearchMapWithClusters_showBreadCrumbs__9nXFy {
  top: 10px; }

@media only screen and (max-width: 640px) {
  .SearchMapWithClusters_filters-filters-open__3c7-M {
    display: none; }
  .SearchMapWithClusters_adjustedBoatsText__3I5SM {
    top: 65px;
    position: relative;
    margin-bottom: 65px; }
  .SearchMapWithClusters_dragSearchToggle__2VsU6 {
    position: fixed;
    top: 60px;
    width: 100%;
    left: 0;
    z-index: 10;
    text-align: center; }
    .SearchMapWithClusters_dragSearchToggle__2VsU6 > div {
      display: inline-block; } }

.SearchMapWithClusters_SearchMapPanel__844Gh {
  padding: 1em;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff; }

.SearchMapWithClusters_SearchMapPanelCheckBoxWrapper__10fva {
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  display: flex !important; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.HoverCard_Button__3pEgC, .HoverCard_ButtonRenter__32kI6, .HoverCard_ButtonOwner__3KXp4, .HoverCard_ButtonYellow__3M2oc, .HoverCard_ButtonOwnerOutline__1f8s_, .HoverCard_ButtonCaptain___nXpI {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.HoverCard_ButtonRenter__32kI6 {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .HoverCard_ButtonRenter__32kI6:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .HoverCard_ButtonRenter__32kI6:active {
    background: #083170;
    border: 1px solid #083170; }

.HoverCard_ButtonOwner__3KXp4 {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .HoverCard_ButtonOwner__3KXp4:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .HoverCard_ButtonOwner__3KXp4:active {
    background: #154353;
    border: 1px solid #154353; }

.HoverCard_ButtonYellow__3M2oc {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .HoverCard_ButtonYellow__3M2oc:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .HoverCard_ButtonYellow__3M2oc:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.HoverCard_ButtonOwnerOutline__1f8s_ {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .HoverCard_ButtonOwnerOutline__1f8s_:hover {
    background: #154353;
    color: white; }

.HoverCard_ButtonCaptain___nXpI {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .HoverCard_ButtonCaptain___nXpI:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .HoverCard_ButtonCaptain___nXpI:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.HoverCard_ButtonSmall__2UvDb {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.HoverCard_HoverCardWrapper__ABvfK {
  display: flex !important;
  flex-direction: column !important;
  position: relative;
  z-index: 900;
  border-radius: 14px;
  overflow: hidden; }

.HoverCard_HoverCardIsCluster__1tFQd {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.HoverCard_BoatCardHeader__220BM {
  position: relative;
  margin: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  font-family: "AvenirNext";
  height: 163px;
  background-color: #fff; }

.HoverCard_BoatCardImage__1ds8D {
  height: auto;
  width: 100%; }

.HoverCard_BoatCardContent__1iEeF {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding: 13px 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "AvenirNext";
  background-color: #fff;
  min-height: 90px; }

.HoverCard_BoatCardContentTop__2gh-e {
  justify-content: space-between !important;
  display: flex !important;
  background-color: #fff; }

.HoverCard_BoatCardLocation__1DgaL {
  font-size: 10px;
  line-height: 15px;
  color: #8e9697 !important;
  text-transform: uppercase;
  margin-bottom: 0.5em; }

.HoverCard_BoatCardReviews__uKhbI {
  font-size: 0.75em;
  line-height: 1.5;
  margin-bottom: 0;
  color: #8e9697 !important; }

.HoverCard_BoatCardTitle__1UrbN {
  text-align: left;
  display: block;
  width: 100%;
  margin: 0 0.3em 0 0;
  height: auto;
  font-size: 15px;
  font-weight: 600;
  color: #17233c;
  line-height: 20px;
  font-family: AvenirNext;
  margin-top: 4px; }

.HoverCard_BoatCardHasReviews__YfGaO {
  color: #4b5765;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0; }

.HoverCard_HoverCardHeader__3cveQ {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background: var(--deep-sea) 0% 0% no-repeat padding-box;
  background: #001d58 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #00000040;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff; }

.HoverCard_BoatPrice__BmTpP {
  z-index: 99999;
  position: absolute;
  right: 11px;
  bottom: 11px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 11px;
  letter-spacing: 0px;
  color: #17233c;
  opacity: 1;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  font-family: "AvenirNext"; }

.carousel .control-dots {
  top: 0;
  bottom: unset;
  text-align: center; }

.carousel .slider-wrapper {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.slide {
  background-color: transparent; }

.HoverCard_BoatCardInstant__3IY8C {
  margin-right: 0.5em;
  height: 20px !important;
  width: unset !important; }

.HoverCard_LeftCaret__KQU2N {
  cursor: pointer;
  padding: 1em; }
  .HoverCard_LeftCaret__KQU2N:before {
    color: #f9fafe; }

.HoverCard_RightCaret__wcVWn {
  cursor: pointer;
  padding: 1em; }
  .HoverCard_RightCaret__wcVWn:before {
    color: #f9fafe; }

.HoverCard_CaretDisabled__2MKBI:before {
  color: #8e9697;
  cursor: not-allowed; }

.HoverCard_BoatCardDetails__svs6b {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #5e696a;
  margin-top: 8px;
  padding-bottom: 6px;
  font-family: AvenirNext; }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.ToolTip_Button__2vUtf, .ToolTip_ButtonRenter__2yt0z, .ToolTip_ButtonOwner__2pXHb, .ToolTip_ButtonYellow__3xHx4, .ToolTip_ButtonOwnerOutline__h-4-o, .ToolTip_ButtonCaptain__3loOv {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.ToolTip_ButtonRenter__2yt0z {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .ToolTip_ButtonRenter__2yt0z:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .ToolTip_ButtonRenter__2yt0z:active {
    background: #083170;
    border: 1px solid #083170; }

.ToolTip_ButtonOwner__2pXHb {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .ToolTip_ButtonOwner__2pXHb:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .ToolTip_ButtonOwner__2pXHb:active {
    background: #154353;
    border: 1px solid #154353; }

.ToolTip_ButtonYellow__3xHx4 {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .ToolTip_ButtonYellow__3xHx4:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .ToolTip_ButtonYellow__3xHx4:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.ToolTip_ButtonOwnerOutline__h-4-o {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .ToolTip_ButtonOwnerOutline__h-4-o:hover {
    background: #154353;
    color: white; }

.ToolTip_ButtonCaptain__3loOv {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .ToolTip_ButtonCaptain__3loOv:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .ToolTip_ButtonCaptain__3loOv:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.ToolTip_ButtonSmall__2ESiU {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

.ToolTip_ToolTipWrapper__16wk9 {
  padding-left: 12px;
  padding-right: 24px; }

.ToolTip_ToolTipCloseStyle__3636b {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 17px;
  height: 17px; }

.ToolTip_ToolTipCloseMarkLeft__2n5iI {
  position: absolute;
  width: 17px;
  height: 0px;
  left: 0px;
  top: 8px;
  border-bottom: 1px solid #4b5765;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0); }

.ToolTip_ToolTipCloseMarkRight__10oOT {
  position: absolute;
  width: 17px;
  height: 0px;
  left: 0px;
  top: 8px;
  border-bottom: 1px solid #4b5765;
  transform: matrix(-0.71, 0.71, -0.71, -0.71, 0, 0); }

/* Boatsetter Colors */
/* success */
/* danger */
/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.ToggleButton_Button__voQhl, .ToggleButton_ButtonRenter__2V9tS, .ToggleButton_ButtonOwner__yD5xH, .ToggleButton_ButtonYellow__1e8rl, .ToggleButton_ButtonOwnerOutline__3qmlz, .ToggleButton_ButtonCaptain__3Ujpi {
  background: white;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 0 18px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  height: 55px; }

.ToggleButton_ButtonRenter__2V9tS {
  background: #0751c2;
  border: 1px solid #0751c2; }
  .ToggleButton_ButtonRenter__2V9tS:hover {
    background: #0a4195;
    border: 1px solid #0a4195; }
  .ToggleButton_ButtonRenter__2V9tS:active {
    background: #083170;
    border: 1px solid #083170; }

.ToggleButton_ButtonOwner__yD5xH {
  background: #0e7873;
  border: 1px solid #0e7873; }
  .ToggleButton_ButtonOwner__yD5xH:hover {
    background: #0c605c;
    border: 1px solid #0c605c; }
  .ToggleButton_ButtonOwner__yD5xH:active {
    background: #154353;
    border: 1px solid #154353; }

.ToggleButton_ButtonYellow__1e8rl {
  background: #f4c064;
  border: 1px solid #f4c064;
  color: #17233c; }
  .ToggleButton_ButtonYellow__1e8rl:hover {
    background: #e7a433;
    border: 1px solid #e7a433; }
  .ToggleButton_ButtonYellow__1e8rl:active {
    background: #d28d19;
    border: 1px solid #d28d19; }

.ToggleButton_ButtonOwnerOutline__3qmlz {
  background: transparent;
  border: 1px solid #154353;
  color: #154353; }
  .ToggleButton_ButtonOwnerOutline__3qmlz:hover {
    background: #154353;
    color: white; }

.ToggleButton_ButtonCaptain__3Ujpi {
  background: #00acb7;
  border: 1px solid #00acb7; }
  .ToggleButton_ButtonCaptain__3Ujpi:hover {
    background: #02949d;
    border: 1px solid #02949d; }
  .ToggleButton_ButtonCaptain__3Ujpi:active {
    background: #0e7873;
    border: 1px solid #0e7873; }

.ToggleButton_ButtonSmall__3hDzs {
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600; }

/* Boatsetter Colors */
/* success */
/* danger */
.ToggleButton_ToggleContainer__YsQ_k {
  min-width: 79px;
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px; }

.ToggleButton_ToggleButton__JCAfh {
  border-radius: 6px;
  width: 100%;
  text-transform: uppercase;
  font-family: "AvenirNext";
  font-size: 10px;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center; }
  .ToggleButton_ToggleButton__JCAfh.ToggleButton_DefaultTrue__3CSiS {
    background-color: #00acb7; }
  .ToggleButton_ToggleButton__JCAfh.ToggleButton_DefaultFalse__1UQNw {
    background-color: #2a8500; }

.ToggleButton_HoverTextSpan__X6B-n {
  position: absolute;
  top: -58px;
  left: -1px;
  width: 279px;
  z-index: 3;
  min-height: 46px;
  overflow: visible;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 10px;
  font-weight: 400;
  text-transform: none;
  padding-top: 8px;
  padding-bottom: 8px; }

.ToggleButton_ButtonText__3Eg2W {
  color: #FFFFFF; }

.ToggleButton_Triangle__1HXRw {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #FFFFFF;
  font-size: 0;
  line-height: 0; }
  .ToggleButton_Triangle__1HXRw.ToggleButton_Left__28G7- {
    top: 43px;
    left: 27px; }

/*# sourceMappingURL=prod.css.map */